import { ActionButton } from '@elotech/components';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

const AcessosList = ({
  disabled,
  arquivos,
  excluirArquivo,
  downloadArquivo
}) => {
  return (
    <Fragment>
      <div className="form-group">
        <div className="panel table table-responsive">
          <div className="panel-body">
            <div className="panel-table-outer ">
              <table className="panel-table striped fancy">
                <thead>
                  <tr>
                    <th>Nome</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {arquivos &&
                    arquivos.map((arquivo, index) => {
                      return (
                        <tr key={index}>
                          <td>{arquivo.nomeDocumento}</td>
                          <td>
                            <div className="btn-actions">
                              <div className="btn-actions-inner">
                                <ActionButton
                                  data-test-id={`buttonDownloadArquivo${index}`}
                                  icon="download"
                                  label="Download"
                                  onClick={() => {
                                    downloadArquivo(index);
                                  }}
                                />
                                {disabled ? (
                                  ''
                                ) : (
                                  <>
                                    <ActionButton
                                      data-test-id={`buttonExcluirArquivo${index}`}
                                      icon="trash-alt"
                                      label="Excluir"
                                      onClick={() => {
                                        excluirArquivo(index);
                                      }}
                                    />
                                  </>
                                )}
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

AcessosList.propTypes = {
  arquivos: PropTypes.array.isRequired,
  excluirArquivo: PropTypes.func.isRequired,
  downloadArquivo: PropTypes.func.isRequired
};

export default AcessosList;
