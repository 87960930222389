import {
  ActionButton,
  ActionsGroup,
  Alert,
  Button,
  Checkbox,
  Field,
  PagedResponse,
  Panel,
  SearchFilter,
  SearchPagination,
  Table,
  TableChild,
  formatUtils,
  usePagedQuery
} from '@elotech/components';
import { AxiosPromise } from 'axios';
import React, { useCallback, useMemo, useState } from 'react';

import { ContribuinteDeclaracaoMensalNaoEntregueService } from '../../../service';
import {
  DeclaracaoMensalNaoEntregueCadastro,
  DeclaracaoMensalNaoEntregueContribuinte,
  DeclaracaoMensalNaoEntregueControle
} from '../../../types';
import ContribuinteDeclaracaoMensalNaoEntregueCompetencia from './ContribuinteDeclaracaoMensalNaoEntregueCompetencia';

type Props = {
  controleDmsNaoEntregues: DeclaracaoMensalNaoEntregueControle;
  selecionados: DeclaracaoMensalNaoEntregueCadastro[];
  onChangeSelecionados: (
    newSomeState: DeclaracaoMensalNaoEntregueCadastro[]
  ) => void;
  todosRegistrosSelecionados: boolean;
  setTodosRegistrosSelecionados: (newSomeState: boolean) => void;
  setQuantidadeTotalPesquisa: (newSomeState: number) => void;
};

const searchFields: Field[] = [
  {
    label: 'Cadastro',
    name: 'cadastroGeral',
    type: 'NUMBER'
  },
  {
    label: 'CPF/CNPJ',
    name: 'cnpjCpf',
    type: 'STRING'
  },
  {
    label: 'Nome',
    name: 'nome',
    type: 'STRING'
  },
  {
    label: 'Mês',
    name: 'mesCompetencia',
    type: 'NUMBER'
  },
  {
    label: 'Ano',
    name: 'anoCompetencia',
    type: 'NUMBER'
  },
  {
    label: 'Prestador',
    name: 'prestador',
    type: 'BOOLEAN'
  },
  {
    label: 'Tomador',
    name: 'tomador',
    type: 'BOOLEAN'
  },
  {
    label: 'Status Multa',
    name: 'statusmulta',
    type: 'ENUM',
    options: [
      {
        name: 'erro',
        descricao: 'Erro na geração'
      },
      {
        name: 'gerado',
        descricao: 'Multa gerada'
      },
      {
        name: 'naogerado',
        descricao: 'Não gerada'
      }
    ]
  }
];

const ContribuinteDeclaracaoMensalNaoEntregueListPage: React.FC<Props> = ({
  controleDmsNaoEntregues,
  setQuantidadeTotalPesquisa,
  selecionados,
  todosRegistrosSelecionados,
  onChangeSelecionados,
  setTodosRegistrosSelecionados
}) => {
  const { id } = controleDmsNaoEntregues;

  const [expandedIndexes, setExpandedIndexes] = useState<number[]>([]);
  const [erroExpandedIndexes, setErroExpandedIndexes] = useState<number[]>([]);
  const [showHeaderTable, setShowHeaderTable] = useState<boolean>(false);

  const {
    doSearch,
    doPagedSearch,
    loading,
    pagination,
    lastSearch,
    values
  } = usePagedQuery<DeclaracaoMensalNaoEntregueCadastro>({
    search: useCallback(
      (search, page) => {
        setExpandedIndexes([]);
        setErroExpandedIndexes([]);
        let resultSearch: AxiosPromise<PagedResponse<
          DeclaracaoMensalNaoEntregueContribuinte
        >> = ContribuinteDeclaracaoMensalNaoEntregueService.searchCadastros(
          id!,
          search,
          page
        );
        resultSearch.then(data =>
          setQuantidadeTotalPesquisa(data.data.totalElements)
        );
        return resultSearch;
      },
      [id, setQuantidadeTotalPesquisa]
    ),
    onError: useCallback(error => {
      Alert.error({ title: `Erro ao buscar os cadastros` }, error);
    }, [])
  });

  const onExpandIndex = (
    _: DeclaracaoMensalNaoEntregueCadastro,
    index: number
  ) => {
    if (erroExpandedIndexes.includes(index)) {
      onErroExpandIndex(_, index);
    }
    if (expandedIndexes.includes(index)) {
      const newIndexes = expandedIndexes.filter(value => value !== index);
      return setExpandedIndexes(newIndexes);
    } else {
      setExpandedIndexes((prevIndexTable: number[]) => {
        return [...prevIndexTable, index];
      });
    }
  };

  const onErroExpandIndex = (
    _: DeclaracaoMensalNaoEntregueCadastro,
    index: number
  ) => {
    if (expandedIndexes.includes(index)) {
      onExpandIndex(_, index);
    }
    if (erroExpandedIndexes.includes(index)) {
      const newIndexes = erroExpandedIndexes.filter(value => value !== index);
      return setErroExpandedIndexes(newIndexes);
    }
    setErroExpandedIndexes((prevIndexTable: number[]) => {
      return [...prevIndexTable, index];
    });
  };

  const defaultFiltro = {
    cadastroGeral: '',
    competenciaInicial: '',
    competenciaFinal: '',
    regimesFiscais: [],
    somentePrestador: false,
    somenteTomador: false,
    tipoMovimentoSigla: ''
  };

  const renderInnerComponent = (
    item: DeclaracaoMensalNaoEntregueCadastro,
    index: number
  ) => {
    if (expandedIndexes.length > 0 && expandedIndexes.includes(index)) {
      const filtro = controleDmsNaoEntregues?.filtro
        ? JSON.parse(controleDmsNaoEntregues?.filtro)
        : defaultFiltro;
      return (
        <ContribuinteDeclaracaoMensalNaoEntregueCompetencia
          idControle={id!}
          item={item}
          lastSearch={lastSearch}
          filtro={filtro}
        />
      );
    }
    if (erroExpandedIndexes.length > 0 && erroExpandedIndexes.includes(index)) {
      return (
        <TableChild>
          <Panel isTable>{item.descricaoErroMulta}</Panel>
        </TableChild>
      );
    }
    return null;
  };

  const keyExtractor = (item: DeclaracaoMensalNaoEntregueCadastro) =>
    `${item.tipoCadastro}-${item.cadastroGeral}`;

  const dmsNaoEntregueSelecionadosMap = useMemo(
    () =>
      selecionados.reduce(
        (result, current) => ({ ...result, [keyExtractor(current)]: true }),
        {}
      ),
    [selecionados]
  );

  const allChecked = useMemo(() => {
    const dmsNaoEntregueFiltrados = values.filter(value => {
      return dmsNaoEntregueSelecionadosMap[keyExtractor(value)]
        ? value
        : undefined;
    });

    let isAllChecked =
      values.length > 0 && dmsNaoEntregueFiltrados.length === values.length;
    if (!isAllChecked && !todosRegistrosSelecionados) {
      setShowHeaderTable(false);
    }
    if (todosRegistrosSelecionados) {
      return !isAllChecked;
    }
    return isAllChecked;
  }, [dmsNaoEntregueSelecionadosMap, values, todosRegistrosSelecionados]);

  const checkAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    if (checked) {
      setShowHeaderTable(true);
    }

    if (
      (checked && !todosRegistrosSelecionados) ||
      (!checked && todosRegistrosSelecionados)
    ) {
      const difference = values.filter(value => {
        return !dmsNaoEntregueSelecionadosMap[keyExtractor(value)]
          ? value
          : undefined;
      });
      onChangeSelecionados([...selecionados, ...difference]);
      return;
    }

    const idsPaginaAtual = values.map(keyExtractor);
    const removed = selecionados.filter(
      x => !idsPaginaAtual.includes(keyExtractor(x))
    );
    onChangeSelecionados(removed);
  };

  const mudaValor = (item: DeclaracaoMensalNaoEntregueCadastro) => {
    const key = keyExtractor(item);
    if (dmsNaoEntregueSelecionadosMap[key]) {
      return onChangeSelecionados(
        selecionados.filter(p => keyExtractor(p) !== keyExtractor(item))
      );
    }
    onChangeSelecionados([...selecionados, item]);
  };

  const getQuantidadeSelecionado = (): number => {
    if (pagination?.totalElements! === 0) {
      return 0;
    }

    if (todosRegistrosSelecionados) {
      return pagination?.totalElements! - selecionados.length;
    }

    return pagination?.totalElements! + selecionados.length;
  };

  const renderHeaderTable = () => {
    if (!showHeaderTable) {
      return <></>;
    }
    return (
      <div className="row custom-header">
        <div className="col-md-5 center">
          {!todosRegistrosSelecionados ? (
            <p>
              Todos os <b className="primary-color">{values.length}</b>{' '}
              cadastros nesta página estão selecionados.
              <Button
                style={{ display: 'inline' }}
                text
                onClick={() => {
                  setTodosRegistrosSelecionados(true);
                  onChangeSelecionados([]);
                }}
              >
                <b className="primary-color">
                  Selecionar todos os {pagination?.totalElements} cadastros
                </b>
              </Button>
            </p>
          ) : (
            <p>
              {pagination?.totalElements! > getQuantidadeSelecionado() ? (
                <>
                  <b className="primary-color">{getQuantidadeSelecionado()}</b>{' '}
                  cadastros de{' '}
                  <b className="primary-color">{pagination?.totalElements}</b>{' '}
                  estão selecionados.
                </>
              ) : (
                <>
                  Todas os{' '}
                  <b className="primary-color">{pagination?.totalElements}</b>{' '}
                  cadastros estão selecionados.
                </>
              )}

              <Button
                style={{ display: 'inline' }}
                text
                onClick={() => {
                  setTodosRegistrosSelecionados(false);
                  onChangeSelecionados([]);
                }}
              >
                <b className="primary-color">Limpar seleção</b>
              </Button>
            </p>
          )}
        </div>
      </div>
    );
  };

  const getSituacao = (item: DeclaracaoMensalNaoEntregueCadastro) => {
    if (!item.descricaoErroMulta && !item.idDebitoMulta) {
      return 'Não gerada';
    }
    if (item.descricaoErroMulta) {
      return 'Erro ao gerar a multa';
    }
    if (item.idDebitoMulta) {
      return 'Multa gerada';
    }
  };

  return (
    <Panel isTable>
      <SearchFilter fields={searchFields} search={doSearch} />
      {renderHeaderTable()}
      <Table
        values={values}
        loading={loading}
        keyExtractor={item => `${item.tipoCadastro}-${item.cadastroGeral}`}
        renderInnerComponent={renderInnerComponent}
        highlightError={item => (item.descricaoErroMulta ? true : false)}
      >
        <Table.Column
          headerClassName="column-checkbox no-print"
          className="column-checkbox no-print"
          header={
            <div className="hidden-xs">
              <Checkbox
                id="checkAll"
                checked={allChecked}
                onChange={checkAll}
              />
            </div>
          }
          value={(item: DeclaracaoMensalNaoEntregueCadastro, index) => (
            <Checkbox
              id={`checkbox-${index}`}
              checked={
                todosRegistrosSelecionados
                  ? dmsNaoEntregueSelecionadosMap[keyExtractor(item)] ===
                    undefined
                    ? todosRegistrosSelecionados
                    : !dmsNaoEntregueSelecionadosMap[keyExtractor(item)]
                  : dmsNaoEntregueSelecionadosMap[keyExtractor(item)] ===
                    undefined
                  ? todosRegistrosSelecionados
                  : dmsNaoEntregueSelecionadosMap[keyExtractor(item)]
              }
              onChange={() => mudaValor(item)}
            />
          )}
        />
        <Table.Column<DeclaracaoMensalNaoEntregueCadastro>
          header="Cadastro"
          name="cadastro"
          value={item => `${item.tipoCadastro} - ${item.cadastroGeral}`}
        />
        <Table.Column<DeclaracaoMensalNaoEntregueCadastro>
          header="Nome"
          value={item =>
            `${formatUtils.formatCpfCnpj(item.cnpjCpf)} - ${item.nome}`
          }
        />
        <Table.Column<DeclaracaoMensalNaoEntregueCadastro>
          header="Regime Fiscal"
          value={item => `${item.idRegimeFiscal} - ${item.descricao}`}
        />
        <Table.Column<DeclaracaoMensalNaoEntregueCadastro>
          header="Situação Multa"
          value={item => getSituacao(item)}
        />
        <Table.Column<DeclaracaoMensalNaoEntregueCadastro>
          header={''}
          value={(_, index) => (
            <>
              <ActionsGroup>
                {_.descricaoErroMulta && (
                  <ActionButton
                    key={`expand-erro-${_.cadastroGeral}`}
                    icon="exclamation-triangle"
                    label="Visualizar Erros"
                    onClick={() => onErroExpandIndex(_, index)}
                  />
                )}
                <ActionButton
                  key={`expand-${_.cadastroGeral}`}
                  icon="eye"
                  label="Competências"
                  onClick={() => onExpandIndex(_, index)}
                />
              </ActionsGroup>
            </>
          )}
        />
      </Table>

      {pagination && (
        <SearchPagination page={pagination} searchWithPage={doPagedSearch} />
      )}
    </Panel>
  );
};

export default ContribuinteDeclaracaoMensalNaoEntregueListPage;
