import {
  AutoComplete,
  Checkbox,
  Col,
  Row,
  SectionTitle
} from '@elotech/components';
import React from 'react';

const AgendamentoForm = ({
  dadosAgendamento,
  tipoRepresentanteLegal,
  onChangeParametros,
  onToggleAgendamento,
  showFormAgendamento,
  loadTipoRepresentante,
  onSelectTipoRepresentanteLegal
}) => {
  return (
    <>
      <SectionTitle hasButton={true}>
        Agendamento
        <button
          className="dropdown-arrow"
          data-test-id="button"
          onClick={onToggleAgendamento}
        >
          <i
            className={
              showFormAgendamento ? 'fa fa-chevron-up' : 'fa fa-chevron-down'
            }
          />
        </button>
      </SectionTitle>
      {showFormAgendamento ? (
        <>
          <Row>
            <Col md={6}>
              <div className="form-group">
                <label htmlFor="">Tipo representante legal</label>
                <AutoComplete
                  data-test-id="logomarca"
                  name="TIPOREPRESENTANTELEGAL"
                  className="form-group"
                  value={tipoRepresentanteLegal}
                  onSearch={loadTipoRepresentante}
                  onSelect={onSelectTipoRepresentanteLegal}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Checkbox
                className="form-group"
                name="ISS_AG_DATAFUTURA"
                id="ISS_AG_DATAFUTURA"
                checked={dadosAgendamento.ISS_AG_DATAFUTURA.valor === 'S'}
                onChange={onChangeParametros}
                label="Agendar credenciamento futuro"
              />
            </Col>
            <Col md={3}>
              <Checkbox
                className="form-group"
                name="VALIDAUPLOADARQUIVOPRECAD"
                id="VALIDAUPLOADARQUIVOPRECAD"
                checked={
                  dadosAgendamento.VALIDAUPLOADARQUIVOPRECAD.valor === 'S'
                }
                onChange={onChangeParametros}
                label="Validar upload arquivo pré-cadastro"
              />
            </Col>
            <Col md={3}>
              <Checkbox
                className="form-group"
                name="HABILITAHOMOLOGACAOTEMPORARIA"
                id="HABILITAHOMOLOGACAOTEMPORARIA"
                data-test-id="checkbox-habilita-homologacao"
                checked={
                  dadosAgendamento.HABILITAHOMOLOGACAOTEMPORARIA.valor === 'S'
                }
                onChange={onChangeParametros}
                label="Habilitar homologação temporária"
              />
            </Col>
            <Col md={3}>
              <Checkbox
                className="form-group"
                name="UTILIZA_CREDENCIAMENTO_AUTOMATICO"
                data-test-id="checkbox-habilita-credenciamento-automatico"
                id="UTILIZA_CREDENCIAMENTO_AUTOMATICO"
                checked={
                  dadosAgendamento.UTILIZA_CREDENCIAMENTO_AUTOMATICO.valor ===
                  'S'
                }
                onChange={onChangeParametros}
                label="Utiliza credenciamento automático"
              />
            </Col>
          </Row>
        </>
      ) : (
        ''
      )}
    </>
  );
};

export default AgendamentoForm;
