import {
  Alert,
  Container,
  FAB,
  Hint,
  Loading,
  SearchFilter
} from '@elotech/components';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { HomologacaoService, withService } from '../../../service';
import ConsultarAgendamentoList from './ConsultarAgendamentoList';

export class ConsultarAgendamentoListPage extends Component {
  static propTypes = {
    homologacaoService: PropTypes.object.isRequired
  };

  state = {
    loading: false,
    agendamentos: {}
  };

  fields = [
    {
      label: 'Data Agendado',
      name: 'dataAgendado',
      type: 'DATE'
    },
    {
      label: 'Número de Agendamento',
      name: 'agendamento.id',
      type: 'NUMBER'
    },
    {
      label: 'Número de Inscrição Municipal',
      name: 'agendamento.cadastroGeral.cadastroGeral',
      type: 'NUMBER'
    },
    {
      label: 'CPF Requerente',
      name: 'cpfRequerente',
      type: 'STRING'
    },
    {
      label: 'Situação',
      name: 'situacao',
      type: 'ENUM',
      options: [
        {
          name: 'AUTORIZADO',
          descricao: 'Autorizado'
        },
        {
          name: 'AUTORIZADO_TEMPORARIAMENTE',
          descricao: 'Autorizado Temporariamente'
        },
        {
          name: 'BLOQUEADO',
          descricao: 'Bloqueado'
        },
        {
          name: 'NAO_AUTORIZADO',
          descricao: 'Não Autorizado '
        }
      ]
    },
    {
      label: 'Razão Social',
      name: 'agendamento.cadastroGeral.pessoa.nome',
      type: 'STRING'
    },
    {
      label: 'Tipo Credenciamento',
      name: 'tipoCredenciamento',
      type: 'ENUM',
      options: [
        {
          name: 'EMISSAO_NFSE',
          descricao: 'Emissão de NFS-e'
        },
        {
          name: 'REGIME_ESPECIAL',
          descricao: 'Regime Especial'
        },
        {
          name: 'SUBSTITUTO_TRIBUTARIO',
          descricao: 'Substituto Tributário'
        }
      ]
    },
    {
      label: 'CPF/CNPJ Contribuinte',
      name: 'agendamento.cadastroGeral.pessoa.cnpjCpf',
      type: 'STRING'
    }
  ];

  searchWithPage = page => {
    this.setState({ pageConsulta: page });
    this.serviceSearch(this.state.searchParams, page);
  };

  serviceSearch = (searchParams, page) => {
    this.setState({
      loading: true,
      searchParams
    });

    this.props.homologacaoService
      .loadAgendamentos(searchParams, page)
      .then(this.getLoadAgendamentosSuccess)
      .catch(this.getLoadAgendamentosError);
  };

  getLoadAgendamentosSuccess = response => {
    this.setState({
      agendamentos: response.data,
      loading: false
    });
  };

  getLoadAgendamentosError = error => {
    this.setState({ loading: false });
    Alert.error(
      {
        title: 'Não foi possível carregar os agendamentos.'
      },
      error
    );
  };

  accreditButtonCredencimento = id => {
    this.props.history.push(
      `/acessos-e-permissoes/credenciamento/credenciar/${id}`
    );
  };

  atualizarCadastro = id => {
    this.props.history.push(
      `/acessos-e-permissoes/credenciamento/atualizacao-cadastral/${id}`
    );
  };

  getDescricaoTipoCadastro = codTipoCadastro => {
    switch (codTipoCadastro) {
      case 9:
        return '9 - Cadastro Avulso';
      case 2:
        return '2 - Cadastro Mobiliário';
      default:
        return '';
    }
  };

  printMap = () => {
    const { searchParams } = this.state;
    this.setState({ loading: true });
    this.props.homologacaoService
      .imprimirMapaCredenciamento(searchParams)
      .then(this.onImprimirMapaAgendamentoSuccess)
      .catch(this.onImprimirMapaAgendamentoError);
  };

  onImprimirMapaAgendamentoSuccess = response => {
    this.setState({
      loading: false
    });

    const file = new Blob([response.data], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  };

  onImprimirMapaAgendamentoError = error => {
    this.setState({ loading: false });
    Alert.error(
      {
        title: 'Não foi possível imprimir o mapa de agendamento.'
      },
      error
    );
  };

  render() {
    const { agendamentos, loading } = this.state;
    return (
      <Container
        breadcrumb
        titleRightComponent={
          <a
            href="https://atendimento.elotech.com.br/kb/pt-br/article/null/oxy-issadmin-consultar-agendamento"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Hint
              classes={`inline clean module-color center-right fa-question-circle`}
            >
              Ajuda?
            </Hint>
          </a>
        }
      >
        <Loading loading={loading} />
        <div className="panel table table-responsive">
          <div className="panel-body">
            <SearchFilter fields={this.fields} search={this.serviceSearch} />
            <ConsultarAgendamentoList
              agendamentos={agendamentos}
              searchWithPage={this.searchWithPage}
              credenciarEmpresa={this.accreditButtonCredencimento}
              atualizarEmpresa={this.atualizarCadastro}
              loading={loading}
              getDescricaoTipoCadastro={this.getDescricaoTipoCadastro}
            />
          </div>
        </div>
        <div className="btn-save">
          <FAB
            icon="print"
            iconColor="white"
            title="Imprimir Mapa"
            onClick={this.printMap}
          />
        </div>
      </Container>
    );
  }
}

const ComponentWithService = withService({
  homologacaoService: HomologacaoService
})(ConsultarAgendamentoListPage);

export default ComponentWithService;
