import {
  Alert,
  Col,
  Container,
  DragDropFiles,
  FAB,
  FormikInputDate,
  Hint,
  Loading,
  Panel,
  Row,
  Yup
} from '@elotech/components';
import { Formik, FormikProps } from 'formik';
import React, { useState } from 'react';

import {
  ConfiguracaoParametrosService,
  ImportacaoArquivoPgdasService
} from '../../../service';
import { ArquivoPgdas } from '../../../types';

const initialValue: ArquivoPgdas = { arquivo: [], dataImportacao: '' };

const validationSchema = Yup.object().shape({
  dataImportacao: Yup.date()
    .label('Data arquivo')
    .required()
});

const ImportacaoArquivoPGDASFormPage: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = (form: ArquivoPgdas) => {
    setLoading(true);

    ConfiguracaoParametrosService.findByParamName('CODIGO_TOM')
      .then(response => {
        if (!response?.data) {
          Alert.info({
            title:
              'Para realizar a importação deverá ser parametrizado o Código referente a Tabela de Órgãos e Municípios (TOM)!'
          });

          setLoading(false);

          return;
        }

        importPGDAS(form);
      })
      .catch(error => {
        Alert.error({ title: 'Erro ao importar arquivo PGDAS' }, error);
        setLoading(false);
      });
  };

  const importPGDAS = (form: ArquivoPgdas) => {
    form.arquivo.forEach(value => {
      setLoading(true);
      const formData = new FormData();
      formData.append('files', value);
      ImportacaoArquivoPgdasService.importarDocumentoPgdas(
        formData,
        form.dataImportacao!
      )
        .then(() => {
          Alert.success({
            title: 'Importação Arquivo PGDAS',
            text:
              'Uma notificação será enviada assim que a importação for finalizada'
          });
        })
        .catch(error => {
          Alert.error({ title: 'Erro ao importar arquivo PGDAS' }, error);
        })
        .finally(() => setLoading(false));
    });
  };

  const addFile = (formprops: any, newFile: File) => {
    formprops?.setFieldValue('arquivo', newFile);
  };

  const onRemove = (formprops: any) => {
    formprops?.setFieldValue('arquivo', []);
  };

  return (
    <Container breadcrumb>
      <Loading loading={loading} />
      <Formik<ArquivoPgdas>
        enableReinitialize
        onSubmit={onSubmit}
        initialValues={initialValue}
        validationSchema={validationSchema}
        render={(formProps: FormikProps<ArquivoPgdas>) => (
          <>
            <Panel
              isForm
              title={
                <>
                  Importação Arquivo PGDAS
                  <Hint
                    classes={`inline clean module-color center-left fa-question-circle mobile`}
                  >
                    Formatos aceitos: .zip, .rar
                  </Hint>
                </>
              }
            >
              <Row>
                <FormikInputDate
                  label="Data do Arquivo"
                  name="dataImportacao"
                  size={2}
                />
              </Row>
              <Row>
                <Col sm={12} className="form-group">
                  <DragDropFiles
                    data-testid="[drag-drop-arquivo]"
                    maxSize={2000000000}
                    files={formProps.values.arquivo}
                    onAddFile={file => addFile(formProps, file as File)}
                    onRemoveFile={() => onRemove(formProps)}
                    acceptedFiles={'.zip, .rar'}
                    multiple={false}
                  ></DragDropFiles>
                </Col>
              </Row>
            </Panel>
            {formProps.values?.arquivo?.length > 0 &&
              formProps.values?.dataImportacao?.length > 0 && (
                <div className="btn-save">
                  <FAB
                    data-test-id="btn-save"
                    icon="check"
                    onClick={formProps.submitForm}
                    title="Importar"
                  />
                </div>
              )}
          </>
        )}
      ></Formik>
    </Container>
  );
};

export default ImportacaoArquivoPGDASFormPage;
