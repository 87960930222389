import { Row, SectionTitle } from '@elotech/components';
import React from 'react';

import Parametro from '../component/Parametro';

const SolicitacaoRPSForm = ({ dados, onToggle, showForm, onChange }) => {
  return (
    <>
      <SectionTitle hasButton={true}>
        Solicitação de RPS
        <button
          className="dropdown-arrow"
          data-test-id="button"
          onClick={onToggle}
        >
          <i className={showForm ? 'fa fa-chevron-up' : 'fa fa-chevron-down'} />
        </button>
      </SectionTitle>
      {showForm ? (
        <>
          <Row>
            <Parametro
              type="integer"
              name="QTD_MAX_POR_SOLICITACAO_RPS"
              value={dados.QTD_MAX_POR_SOLICITACAO_RPS.valor || ''}
              onChange={onChange}
              size={4}
              label="Quantidade máxima de RPS por solicitação"
            />
            <Parametro
              type="integer"
              name="PERC_LIB_AUTO_SOLICITACAO_RPS"
              value={dados.PERC_LIB_AUTO_SOLICITACAO_RPS.valor || ''}
              onChange={onChange}
              size={4}
              label="Percentual de RPS disponível para liberação automática"
              onValueChange={({ floatValue }, e) => {
                if (floatValue > 100) e.target.value = 100;
              }}
              hintText="Em relação à quantidade máxima por solicitação"
            />
            <Parametro
              type="checkbox"
              id="INTEGRA_NOTA_SOMENTE_HOMOLOGACAO"
              name="INTEGRA_NOTA_SOMENTE_HOMOLOGACAO"
              value={dados.INTEGRA_NOTA_SOMENTE_HOMOLOGACAO.valor === 'S'}
              onChange={onChange}
              size={4}
              label="Permitir envio de notas somente em homologação"
            />
          </Row>
        </>
      ) : (
        ''
      )}
    </>
  );
};

export default SolicitacaoRPSForm;
