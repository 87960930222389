import {
  Alert,
  Container,
  Hint,
  Info,
  Loading,
  NotificationActions,
  Wizard
} from '@elotech/components';
import b64toBlob from 'b64-to-blob';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import swal from 'sweetalert2';

import {
  CnaeService,
  HomologacaoService,
  TipoImagemService,
  withService
} from '../../../service';
import RegimeFiscalList from './step-regime-fiscal/RegimeFiscalList';
import DadosGerais from './step1-dados-gerais/DadosGerais';
import Atividades from './step2-atividades/Atividades';
import Credenciamento from './step4-credenciamento/Credenciamento';

const INDEX_DADOS_GERAIS = 0;
const INDEX_ATIVIDADE = 1;

export class ConsultarAgendamentoCredenciarPage extends Component {
  static propTypes = {
    homologacaoService: PropTypes.object.isRequired,
    cnaeService: PropTypes.object.isRequired,
    tipoImagemService: PropTypes.object.isRequired
  };

  state = {
    loading: false,
    historicoAgendamento: {
      usuario: {},
      acessos: []
    },
    arquivoEmpresa: {
      id: '',
      idDocumento: '',
      nomeDocumento: '',
      arquivoBase64: '',
      tipoImagem: ''
    },
    showFormArquivos: false,
    showFormAcessos: false,
    showFormOpcoes: false,
    error: {
      observacaoText: false
    },
    errorArquivo: {
      size: false,
      type: false
    },
    cnaeAutoComplete: {
      cnae: ''
    },

    step1: { valid: true, errorMessage: '' },
    step2: { valid: true, errorMessage: '' },
    step4: { valid: true, errorMessage: '' },

    expandedEndereco: false,
    expandedAllServices: false,
    tiposImagens: []
  };

  componentDidMount() {
    const { id } = this.props.match.params;

    if (id) {
      this.setState({ loading: true });
      this.props.homologacaoService
        .loadHistoricoAgendamento(id)
        .then(this.loadHistoricoAgendamentoSuccess)
        .catch(this.loadHistoricoAgendamentoError);
    }

    this.props.tipoImagemService
      .loadTiposImagens()
      .then(this.loadTiposImagensSuccess)
      .catch(this.loadTiposImagensError);
  }

  loadHistoricoAgendamentoSuccess = response => {
    this.setState({ loading: false });
    this.setState(
      {
        historicoAgendamento: response.data
      },
      () => {
        this.setState(state => {
          const { historicoAgendamento } = state;
          return {
            historicoAgendamento: {
              ...historicoAgendamento,
              usuario: this.props.usuario
            }
          };
        });
      }
    );
  };

  loadHistoricoAgendamentoError = error => {
    this.setState({ loading: false });
    Alert.error({ title: 'Não foi possível carregar o agendamento.' }, error);
  };

  loadTiposImagensSuccess = response => {
    this.setState(
      {
        tiposImagens: response.data
      },
      () => {
        this.setState(state => {
          const { tiposImagens } = state;
          if (tiposImagens && tiposImagens.length > 0) {
            return {
              arquivoEmpresa: {
                ...state.arquivoEmpresa,
                tipoImagem: tiposImagens[0].id
              }
            };
          }
        });
      }
    );
  };

  loadTiposImagensError = error => {
    Alert.error(
      { title: 'Não foi possível carregar os tipos de imagens.' },
      error
    );
  };

  excluirArquivo = index => {
    this.setState(state => {
      const { historicoAgendamento } = state;
      const arquivos = historicoAgendamento.arquivos.filter(
        (item, i) => index !== i
      );
      return {
        historicoAgendamento: {
          ...historicoAgendamento,
          arquivos
        }
      };
    });
  };

  downloadArquivo = index => {
    const { historicoAgendamento } = this.state;
    const arquivo = historicoAgendamento.arquivos[index];
    if (arquivo.idDocumento && arquivo.idDocumento > 0) {
      this.setState({ loading: true });
      this.props.homologacaoService
        .downloadArquivo(arquivo.idDocumento)
        .then(this.downloadArquivoSuccess)
        .catch(this.downloadArquivoError);
    } else {
      const blob = b64toBlob(arquivo.arquivoBase64, 'application/pdf');
      const fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    }
  };

  downloadArquivoSuccess = response => {
    this.setState({
      loading: false
    });

    const file = new Blob([response.data], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  };

  downloadArquivoError = error => {
    this.setState({ loading: false });
    Alert.error(
      {
        title: 'Não foi possível fazer o download do arquivo.'
      },
      error
    );
  };

  onUploadFile = event => {
    const { files } = event.target;

    const isNotValidSize = !(files && files[0] && files[0].size < 52428800);
    const isNotValidType = !(
      files &&
      files[0] &&
      files[0].type === 'application/pdf'
    );

    this.setState(state => {
      return {
        arquivoEmpresa: {
          ...state.arquivoEmpresa,
          nomeDocumento: files[0].name
        }
      };
    });

    if (isNotValidSize || isNotValidType) {
      this.setState(state => {
        return {
          errorArquivo: {
            ...state.errorArquivo,
            size: isNotValidSize,
            type: isNotValidType
          }
        };
      });
    } else {
      this.setState(state => {
        const { errorArquivo } = state;
        return {
          errorArquivo: {
            ...errorArquivo,
            size: false,
            type: false
          }
        };
      });

      this.getBase64(files[0])
        .then(data => {
          this.setState(state => {
            return {
              arquivoEmpresa: {
                ...state.arquivoEmpresa,
                arquivoBase64: data
              }
            };
          });

          this.setState(state => {
            const { historicoAgendamento, arquivoEmpresa } = state;
            const arquivos = historicoAgendamento.arquivos.concat(
              arquivoEmpresa
            );

            return {
              historicoAgendamento: {
                ...state.historicoAgendamento,
                arquivos
              },
              arquivoEmpresa: {
                ...state.arquivoEmpresa,
                id: '',
                nomeDocumento: '',
                arquivoBase64: '',
                tipoImagem: ''
              }
            };
          });
        })
        .catch(this.errorUploadFile);
    }
  };

  getBase64 = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let encoded = reader.result.replace(/^data:(.*;base64,)?/, '');
        if (encoded.length % 4 > 0) {
          encoded += '='.repeat(4 - (encoded.length % 4));
        }
        resolve(encoded);
      };
      reader.onerror = error => reject(error);
    });
  };

  errorUploadFile = () => {
    this.props.showNotification({
      level: 'error',
      message: 'Erro ao fazer o upload do arquivo.'
    });
  };

  getCamposAtividades = atividades => {
    const { id, cnae } = atividades;

    const atividadesValido = {
      id,
      cnae
    };
    return atividadesValido;
  };

  onChangeTipoImagem = event => {
    const { name, value } = event.target;

    this.setState(state => {
      const { arquivoEmpresa } = state;
      return {
        arquivoEmpresa: {
          ...arquivoEmpresa,
          [name]: value
        }
      };
    });
  };

  onChangeInputValue = event => {
    const { name, value } = event.target;

    this.setState(state => {
      const { historicoAgendamento } = state;
      return {
        historicoAgendamento: { ...historicoAgendamento, [name]: value }
      };
    });
  };

  filterCnae = search => {
    if (isNaN(search)) {
      return `descricao=='*${search}*'`;
    } else if (search) {
      return `codigo==${search}`;
    }
    return 'codigo==0';
  };

  optionLabelCnae = option => {
    if (option) {
      return option.codigo + ' - ' + option.descricao;
    }
    return '';
  };

  onSelectCnae = (name, value) => {
    this.setState(state => {
      const { cnaeAutoComplete } = state;
      return {
        cnaeAutoComplete: { ...cnaeAutoComplete, [name]: value }
      };
    });
  };

  onClearAutoComplete = () => {
    this.onSelectCnae('cnae', '');
  };

  onAdicionarCnae = () => {
    const { historicoAgendamento, cnaeAutoComplete } = this.state;

    const cnaeCadastrado = historicoAgendamento.cnaes.filter(
      c => c.codigo === cnaeAutoComplete.cnae.codigo
    );

    if (cnaeCadastrado.length <= 0) {
      this.setState(state => {
        const { historicoAgendamento, cnaeAutoComplete } = state;

        const cnaes = historicoAgendamento.cnaes.concat({
          ...cnaeAutoComplete.cnae,
          habilitado: 'N',
          principal: 'N'
        });

        return {
          historicoAgendamento: {
            ...historicoAgendamento,
            cnaes
          }
        };
      });
    } else {
      this.props.showNotification({
        level: 'error',
        message: 'Cnae já cadastrado.'
      });
    }

    this.onClearAutoComplete();
  };

  atribuirCnaePrincipal = index => {
    this.setState(state => {
      const { historicoAgendamento } = state;
      const cnaes = historicoAgendamento.cnaes.map((item, i) => {
        if (index === i) {
          item.principal = 'S';
        } else {
          item.principal = 'N';
        }
        return item;
      });

      return {
        historicoAgendamento: {
          ...historicoAgendamento,
          cnaes
        }
      };
    });
  };

  autorizarOuNaoCnae = (index, autorizar) => {
    this.setState(state => {
      const { historicoAgendamento } = state;

      const cnaes = historicoAgendamento.cnaes.map((item, i) => {
        if (index === i) {
          item.habilitado = autorizar ? 'S' : 'N';
        }
        return item;
      });

      return {
        historicoAgendamento: {
          ...historicoAgendamento,
          cnaes
        }
      };
    });
  };

  excluirCnae = index => {
    this.setState(state => {
      const { historicoAgendamento } = state;
      const cnaes = historicoAgendamento.cnaes.filter((item, i) =>
        index !== i ? item : null
      );

      return {
        historicoAgendamento: {
          ...historicoAgendamento,
          cnaes
        }
      };
    });
  };

  rejeitarOuHomologarCredenciar = (name, homologarOuRejeitar) => {
    this.setState(state => {
      const { historicoAgendamento } = state;

      return {
        historicoAgendamento: {
          ...historicoAgendamento,
          [name]: homologarOuRejeitar
        }
      };
    });
  };

  onBeforeChange = async (oldStepData, newStepData) => {
    const { historicoAgendamento } = this.state;
    let temErro;
    if (newStepData.index < oldStepData.index) {
      return { oldStepData, newStepData };
    }
    if (oldStepData.index === INDEX_DADOS_GERAIS) {
      temErro = false;
    } else if (oldStepData.index === INDEX_ATIVIDADE) {
      !historicoAgendamento.permiteAprovarOuRejeitar
        ? (temErro = false)
        : (temErro = this.validStepAtividades());
    }
    return { oldStepData: { ...oldStepData, valid: !temErro }, newStepData };
  };

  toggleExpandedAllServices = () => {
    this.setState(state => ({
      expandedAllServices: !state.expandedAllServices
    }));
  };

  authorizesAllCnaes = () => {
    this.setState(state => {
      const { historicoAgendamento } = state;

      const cnaes = historicoAgendamento.cnaes.map(item => {
        item.habilitado = 'S';
        return item;
      });

      return {
        historicoAgendamento: {
          ...historicoAgendamento,
          cnaes
        }
      };
    });
  };

  toggleExpanded = () => {
    this.setState(state => ({ expandedEndereco: !state.expandedEndereco }));
  };

  validStepAtividades = () => {
    const { historicoAgendamento } = this.state;

    const existsAtividades = historicoAgendamento.cnaes.length > 0;

    const cnaePrincipal = historicoAgendamento.cnaes.filter(atividade => {
      return atividade.principal === 'S';
    });

    let cnaePrincipalAutorizado;
    if (cnaePrincipal && cnaePrincipal.length > 0) {
      cnaePrincipalAutorizado = cnaePrincipal.filter(atividade => {
        return atividade.habilitado === 'S';
      });
    } else {
      cnaePrincipalAutorizado = [];
    }

    const temErro =
      !existsAtividades ||
      !cnaePrincipalAutorizado.length > 0 ||
      !cnaePrincipal.length > 0;

    let errorMessage;

    if (temErro) {
      if (!existsAtividades) {
        this.props.showNotification({
          level: 'error',
          message: 'Adicione os CNAE de acordo com o enquadramento da empresa.'
        });
      } else if (!cnaePrincipal.length > 0) {
        this.props.showNotification({
          level: 'error',
          message: 'Um CNAE deve ser principal.'
        });
      } else if (!cnaePrincipalAutorizado.length > 0) {
        this.props.showNotification({
          level: 'error',
          message: 'CNAE principal deve ser autorizado.'
        });
      }
      errorMessage = 'Verifique os erros!.';
    }

    this.setState(state => ({
      step2: { ...state.step2, valid: !temErro, errorMessage: errorMessage }
    }));
    return temErro;
  };

  onFinish = () => {
    const { historicoAgendamento } = this.state;
    if (historicoAgendamento.permiteAprovarOuRejeitar) {
      swal({
        title: 'Análise pré-cadastro.',
        text: 'Deseja rejeitar ou aprovar o pré-cadastro?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Rejeitar',
        confirmButtonText: 'Aprovar'
      }).then(result => {
        if (result.value) {
          const { historicoAgendamento } = this.state;
          this.setState({ loading: true });
          this.props.homologacaoService
            .aprovarHomologacao(historicoAgendamento)
            .then(this.onAprovarHomologacaoSuccess)
            .catch(this.onAprovarHomologacaoError);
        } else {
          this.rejeitarAgendamento();
        }
      });
    } else if (!historicoAgendamento.permiteAprovarOuRejeitar) {
      return this.props.history.replace('/acessos-e-permissoes/credenciamento');
    }
  };

  rejeitarAgendamento = () => {
    swal
      .queue([
        {
          title: 'Rejeitar homologação',
          type: 'warning',
          text: 'Motivo rejeição',
          input: 'textarea',
          inputPlaceholder: 'Justificativa',
          showCancelButton: true,
          confirmButtonColor: '#0091e6',
          cancelButtonColor: '#999999',
          cancelButtonText: 'Não',
          confirmButtonText: 'Sim'
        }
      ])
      .then(result => {
        if (result.value) {
          this.setState(
            state => {
              return {
                historicoAgendamento: {
                  ...state.historicoAgendamento,
                  observacaoRejeicao: result.value[0]
                }
              };
            },
            () => {
              const { historicoAgendamento } = this.state;
              this.setState({ loading: true });
              this.props.homologacaoService
                .rejeitarHomologacao(historicoAgendamento)
                .then(this.onRejeitarHomologacaoSuccess)
                .catch(this.onRejeitarHomologacaoError);
            }
          );
        } else if (result.dismiss === swal.DismissReason.cancel) {
          this.props.history.push('/acessos-e-permissoes/credenciamento');
        }
      });
  };

  onAprovarHomologacaoSuccess = response => {
    this.setState({ loading: false });

    Alert.question({
      title: 'Deseja enviar email de notificação de credenciamento da empresa?'
    }).then(result => {
      if (result.value) {
        this.setState({ loading: true });
        const { historicoAgendamento } = this.state;
        this.props.homologacaoService
          .enviarEmail(historicoAgendamento)
          .then(this.enviarEmailSuccess)
          .catch(this.enviarEmailError);
      }
      this.props.history.push('/acessos-e-permissoes/credenciamento');
      this.props.showNotification({
        level: 'success',
        message: 'Agendamento homologado com sucesso.'
      });
    });
  };

  onAprovarHomologacaoError = error => {
    this.setState({ loading: false });
    Alert.error(
      {
        title: 'Não foi possível homologar o cadastro.'
      },
      error
    );
  };

  onRejeitarHomologacaoSuccess = response => {
    this.setState({ loading: false });

    Alert.question({
      title: 'Deseja enviar email de notificação de credenciamento da empresa?'
    }).then(result => {
      if (result.value) {
        this.setState({ loading: true });
        const { historicoAgendamento } = this.state;
        this.props.homologacaoService
          .enviarEmail(historicoAgendamento)
          .then(this.enviarEmailSuccess)
          .catch(this.enviarEmailError);
      }

      this.props.history.push('/acessos-e-permissoes/credenciamento');
      this.props.showNotification({
        level: 'error',
        message: 'Agendamento rejeitado!.'
      });
    });
  };

  onRejeitarHomologacaoError = error => {
    this.setState({ loading: false });
    Alert.error(
      {
        title: 'Não foi possível rejeitar o cadastro.'
      },
      error
    );
  };

  enviarEmailSuccess = response => {
    this.setState({ loading: false });
    this.props.showNotification({
      level: 'success',
      message: 'Email enviado com sucesso.'
    });
  };

  enviarEmailError = error => {
    this.setState({ loading: false });
    Alert.error(
      { title: 'Erro ao enviar o email para o contribuinte.' },
      error
    );
  };

  visualizarAcessos = () => {
    this.setState(state => ({ showFormAcessos: !state.showFormAcessos }));
  };

  visualizarArquivos = () => {
    this.setState(state => ({ showFormArquivos: !state.showFormArquivos }));
  };

  visualizarOpcoes = () => {
    this.setState(state => ({ showFormOpcoes: !state.showFormOpcoes }));
  };

  onExitCredenciamento = () => {
    this.props.goBack();
  };

  render() {
    const {
      loading,
      historicoAgendamento,
      arquivoEmpresa,
      cnaeAutoComplete,
      step1,
      step2,
      step4,
      expandedAllServices,
      showFormAcessos,
      showFormArquivos,
      showFormOpcoes,
      error,
      errorArquivo,
      tiposImagens
    } = this.state;

    const isCadastroAvulso = () => {
      return historicoAgendamento.tipoCadastro === 9;
    };

    return (
      <Container
        breadcrumb
        titleRightComponent={
          <a
            href="https://atendimento.elotech.com.br/kb/pt-br/article/null/oxy-issadmin-consultar-agendamento"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Hint
              classes={`inline clean module-color center-right fa-question-circle`}
            >
              Ajuda?
            </Hint>
          </a>
        }
      >
        <Loading loading={loading} />
        {isCadastroAvulso() && (
          <DadosGerais
            disabled={!historicoAgendamento.permiteAprovarOuRejeitar}
            historicoAgendamento={historicoAgendamento}
            error={error}
            errorArquivo={errorArquivo}
            tiposImagens={tiposImagens}
            visualizarAcessos={this.visualizarAcessos}
            showFormAcessos={showFormAcessos}
            visualizarArquivos={this.visualizarArquivos}
            showFormArquivos={showFormArquivos}
            excluirArquivo={this.excluirArquivo}
            downloadArquivo={this.downloadArquivo}
            arquivoEmpresa={arquivoEmpresa}
            onUploadFile={this.onUploadFile}
            onChangeTipoImagem={this.onChangeTipoImagem}
            showFormOpcoes={showFormOpcoes}
            visualizarOpcoes={this.visualizarOpcoes}
            isCadastroAvulso={true}
            rejeitarOuHomologarCredenciar={this.rejeitarOuHomologarCredenciar}
            onChangeObservacoes={this.onChangeInputValue}
            onFinish={this.onFinish}
          />
        )}
        {!isCadastroAvulso() && (
          <Wizard
            beforeChange={this.onBeforeChange}
            onFinish={this.onFinish}
            allowInvalidChange={false}
            finishButtonOnlyOnLastStep
          >
            <Wizard.Step
              stepId="1"
              label="Dados Gerais"
              icon="fa fa-file-alt"
              valid={step1.valid}
              errorMessage={step1.errorMessage}
            >
              {!historicoAgendamento.permiteAprovarOuRejeitar ? (
                <div className="form-group">
                  <Info classes="mb-xs primary">
                    Somente solicitações de credenciamento com situação 'Não
                    Autorizado' podem ser alterados.
                  </Info>
                </div>
              ) : (
                ''
              )}
              <DadosGerais
                disabled={!historicoAgendamento.permiteAprovarOuRejeitar}
                historicoAgendamento={historicoAgendamento}
                error={error}
                errorArquivo={errorArquivo}
                tiposImagens={tiposImagens}
                visualizarAcessos={this.visualizarAcessos}
                showFormAcessos={showFormAcessos}
                visualizarArquivos={this.visualizarArquivos}
                showFormArquivos={showFormArquivos}
                excluirArquivo={this.excluirArquivo}
                downloadArquivo={this.downloadArquivo}
                arquivoEmpresa={arquivoEmpresa}
                onUploadFile={this.onUploadFile}
                onChangeTipoImagem={this.onChangeTipoImagem}
                showFormOpcoes={showFormOpcoes}
                visualizarOpcoes={this.visualizarOpcoes}
                isCadastroAvulso={false}
              />
            </Wizard.Step>

            <Wizard.Step
              stepId="2"
              label="Atividades"
              icon="fa fa-list-ul"
              valid={step2.valid}
              errorMessage={step2.errorMessage}
            >
              {!historicoAgendamento.permiteAprovarOuRejeitar ? (
                <div className="form-group">
                  <Info classes="mb-xs primary">
                    Somente solicitações de credenciamento com situação 'Não
                    Autorizado' podem ser alterados.
                  </Info>
                </div>
              ) : (
                ''
              )}
              <Atividades
                disabled={!historicoAgendamento.permiteAprovarOuRejeitar}
                historicoAgendamento={historicoAgendamento}
                cnaeAutoComplete={cnaeAutoComplete}
                loadCnaes={this.props.cnaeService.loadCnaes}
                onSelectCnae={this.onSelectCnae}
                filterCnae={this.filterCnae}
                optionLabelCnae={this.optionLabelCnae}
                onClearAutoComplete={this.onClearAutoComplete}
                onAdicionarCnae={this.onAdicionarCnae}
                atribuirCnaePrincipal={this.atribuirCnaePrincipal}
                autorizarOuNaoCnae={this.autorizarOuNaoCnae}
                toggleExpandedAllServices={this.toggleExpandedAllServices}
                authorizesAllCnaes={this.authorizesAllCnaes}
                expandedAllServices={expandedAllServices}
                excluirCnae={this.excluirCnae}
              />
            </Wizard.Step>

            <Wizard.Step
              stepId="3"
              label="Regime Fiscal"
              icon="fa fa-file-signature"
            >
              <RegimeFiscalList
                regimesFiscais={historicoAgendamento.regimesFiscais}
              />
            </Wizard.Step>

            <Wizard.Step
              stepId="4"
              label="Credenciamento"
              icon="fa fa-medal"
              valid={step4.valid}
              errorMessage={step4.errorMessage}
            >
              {!historicoAgendamento.permiteAprovarOuRejeitar ? (
                <div className="form-group">
                  <Info classes="mb-xs primary">
                    Somente solicitações de credenciamento com situação 'Não
                    Autorizado' podem ser alterados.
                  </Info>
                </div>
              ) : (
                ''
              )}
              <Credenciamento
                disabled={!historicoAgendamento.permiteAprovarOuRejeitar}
                historicoAgendamento={historicoAgendamento}
                rejeitarOuHomologarCredenciar={
                  this.rejeitarOuHomologarCredenciar
                }
                onChangeObservacoes={this.onChangeInputValue}
                isCadastroAvulso={false}
              />
            </Wizard.Step>
          </Wizard>
        )}
      </Container>
    );
  }
}

const ComponentWithService = withService({
  homologacaoService: HomologacaoService,
  cnaeService: CnaeService,
  tipoImagemService: TipoImagemService
})(ConsultarAgendamentoCredenciarPage);

const mapDispatchToProps = {
  showNotification: NotificationActions.showNotification
};

const mapStateToProps = state => ({
  usuario: state.user.profile
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComponentWithService);
