import axios, { AxiosPromise } from 'axios';

import { CONTEXT_PATH } from './contextPath';

export const importarDocumentoPgdas = (
  files: any,
  dataImportacao: string
): AxiosPromise<any> =>
  axios.post(
    `${CONTEXT_PATH}/pgdas-valores/importar-documento-pgdas/${dataImportacao}`,
    files
  );
