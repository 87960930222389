import { BreadCrumbRoute } from '@elotech/components';

import ControleDeclaracaoMensalNaoEntregueFormPage from '../pages/mais/controle-dms-nao-entregue/ControleDeclaracaoMensalNaoEntregueFormPage';
import ControleDeclaracaoMensalNaoEntregueListPage from '../pages/mais/controle-dms-nao-entregue/ControleDeclaracaoMensalNaoEntregueListPage';
import ControleDeclaracaoMensalNaoEntregueMultaFormPage from '../pages/mais/controle-dms-nao-entregue/ControleDeclaracaoMensalNaoEntregueMultaFormPage';
import ControleDeclaracaoMensalNaoEntregueViewPage from '../pages/mais/controle-dms-nao-entregue/ControleDeclaracaoMensalNaoEntregueViewPage';
import CosifListPage from '../pages/mais/cosif/CosifListPage';
import EditarNfseListPage from '../pages/mais/editar-nfse/EditarNfseListPage';
import EditarTomadorFormPage from '../pages/mais/editar-nfse/EditarTomadorFormPage';
import ExportarNfseListPage from '../pages/mais/exportar-nfse/ExportarNfseListPage';
import ImportacaoNfseNacionalListPage from '../pages/mais/importaca-nfse-nacional/ImportacaoNfseNacionalListPage';
import ImportacaoArquivoDIMPFormPage from '../pages/mais/importacao-arquivo-dimp/ImportacaoArquivoDIMPFormPage';
import ImportacaoArquivoPGDASFormPage from '../pages/mais/importacao-arquivo-pgdas/ImportacaoArquivoPGDASFormPage';
import CadastroRubricaPlanoOrientadorFormPage from '../pages/mais/plano-orientador/CadastroRubricaPlanoOrientadorFormPage';
import PlanoOrientadorListPage from '../pages/mais/plano-orientador/PlanoOrientadorListPage';
import ViradaExercicioFormPage from '../pages/mais/virada-exercicio/ViradaExercicioFormPage';
import { Roles } from '../utils/Roles';

export const routesRotinas: BreadCrumbRoute[] = [
  {
    path: '/rotinas/editar-nfse',
    component: EditarNfseListPage,
    icon: 'edit',
    title: 'Editar NFSe',
    role: Roles.nfse_update.name
  },
  {
    path: '/rotinas/editar-tomador/:id',
    component: EditarTomadorFormPage,
    icon: 'edit',
    title: 'Editar NFSe',
    parent: '/rotinas/editar-tomador'
  },
  {
    path: '/rotinas/plano-orientador',
    component: PlanoOrientadorListPage,
    icon: 'university',
    title: 'Plano de Contas Orientador',
    role: Roles.plano_orientador_read.name
  },
  {
    path: '/plano-orientador/cadastro-rubrica',
    component: CadastroRubricaPlanoOrientadorFormPage,
    icon: 'university',
    title: 'Cadastro Rubrica'
  },
  {
    path: '/plano-orientador/cadastro-rubrica/novo',
    component: CadastroRubricaPlanoOrientadorFormPage,
    icon: 'file',
    title: 'Novo',
    parent: '/rotinas/plano-orientador'
  },
  {
    path: '/plano-orientador/cadastro-rubrica/editar/:id',
    component: CadastroRubricaPlanoOrientadorFormPage,
    icon: 'pencil-alt',
    title: 'Editar',
    parent: '/rotinas/plano-orientador'
  },
  {
    path: '/rotinas/virada-exercicio',
    component: ViradaExercicioFormPage,
    icon: 'file-alt',
    title: 'Virada Exercício',
    role: Roles.virada_exercicio_update.name
  },
  {
    path: '/rotinas/controle-dms-nao-entregues',
    component: ControleDeclaracaoMensalNaoEntregueListPage,
    icon: 'edit',
    title: 'Controle de DMS Não Entregues',
    role: Roles.controle_dms_nao_entregues_read.name
  },
  {
    path: '/rotinas/controle-dms-nao-entregues/:id/resumo',
    component: ControleDeclaracaoMensalNaoEntregueViewPage,
    icon: 'edit',
    title: 'Resumo',
    parent: '/rotinas/controle-dms-nao-entregues',
    role: Roles.controle_dms_nao_entregues_read.name
  },
  {
    path: '/rotinas/controle-dms-nao-entregues/:id/resumo/multa',
    component: ControleDeclaracaoMensalNaoEntregueMultaFormPage,
    parentURLBuilder: currentRoute =>
      `${currentRoute.matchedURL!.match(
        /\/rotinas\/controle-dms-nao-entregues\/\d+/
      )}/resumo`,
    icon: 'edit',
    title: 'Multa',
    parent: '/rotinas/controle-dms-nao-entregues/:id/resumo',
    role: Roles.controle_dms_nao_entregues_read.name
  },
  {
    path: '/rotinas/controle-dms-nao-entregues/:id',
    component: ControleDeclaracaoMensalNaoEntregueFormPage,
    icon: 'edit',
    title: 'Novo',
    parent: '/rotinas/controle-dms-nao-entregues',
    role: Roles.controle_dms_nao_entregues_write.name
  },
  {
    path: '/rotinas/importacao-arquivo-dimp',
    component: ImportacaoArquivoDIMPFormPage,
    icon: 'edit',
    title: 'Importação Arquivo DIMP',
    role: Roles.importacao_arquivo_dimp_update.name
  },
  {
    path: '/rotinas/importacao-nfse-nacional',
    component: ImportacaoNfseNacionalListPage,
    icon: 'file-import',
    title: 'Importação NFS-e - Padrão Nacional',
    role: Roles.importacao_nfse_nacional_read.name
  },
  {
    path: '/rotinas/cosif',
    component: CosifListPage,
    icon: 'edit',
    title: 'Cosif',
    role: Roles.cosif_read.name
  },
  {
    path: '/rotinas/exportar-nfse',
    component: ExportarNfseListPage,
    icon: 'file-download',
    title: 'Exportar NFSe',
    role: Roles.nfse_exportar.name
  },
  {
    path: '/rotinas/importacao-arquivo-pgdas',
    component: ImportacaoArquivoPGDASFormPage,
    icon: 'file-download',
    title: 'Importação Arquivo PGDAS',
    role: Roles.importacao_arquivo_pgdas_update.name
  }
];

export default routesRotinas;
