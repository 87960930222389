import {
  Checkbox,
  Col,
  Hint,
  InputDate,
  InputInteger,
  Row,
  SectionTitle
} from '@elotech/components';
import React from 'react';

import Parametro from '../component/Parametro';

const NfseForm = ({
  dadosNfse,
  tiposFluxoCancelamentoNfse,
  tiposFluxoSubstituicaoNfse,
  onChangeParametros,
  onToggleNfse,
  showFormNfse
}) => {
  return (
    <>
      <SectionTitle hasButton={true}>
        NFS-e
        <button
          className="dropdown-arrow"
          data-test-id="button"
          onClick={onToggleNfse}
        >
          <i
            className={showFormNfse ? 'fa fa-chevron-up' : 'fa fa-chevron-down'}
          />
        </button>
      </SectionTitle>
      {showFormNfse ? (
        <div className="form">
          <Row>
            <Col md={4}>
              <div className="form-group">
                <label htmlFor="">
                  Dias limites para retroagir a data de emissão da NFS-e Web
                </label>
                <Hint
                  classes={`inline clean module-color top-left fa-exclamation-circle mobile xs`}
                >
                  Quantidade de dias
                </Hint>
                <InputInteger
                  name="DIASLIMITEEMISSAONFSEWEB"
                  key="DIASLIMITEEMISSAONFSEWEB"
                  value={dadosNfse.DIASLIMITEEMISSAONFSEWEB.valor || ''}
                  onChange={onChangeParametros}
                  autoComplete="off"
                />
              </div>
            </Col>
            <Col md={4}>
              <div className="form-group">
                <label htmlFor="">Percentual máximo dedução da NFS-e</label>
                <input
                  type="number"
                  name="PERCENTUALMAXDEDUCAONFSE"
                  key="PERCENTUALMAXDEDUCAONFSE"
                  value={dadosNfse.PERCENTUALMAXDEDUCAONFSE.valor}
                  onChange={onChangeParametros}
                  autoComplete="off"
                />
              </div>
            </Col>
            <Col md={4}>
              <div className="form-group">
                <label htmlFor="">Competência DMS não entregues</label>
                <InputDate
                  name="COMPINICIOVERIFICACAODMS"
                  value={dadosNfse.COMPINICIOVERIFICACAODMS.valor || ''}
                  onChange={onChangeParametros}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <div className="form-group">
                <label htmlFor="">URL site para autenticar NFS-e</label>
                <input
                  type="text"
                  name="SITEAUTENTICARNFSE"
                  key="SITEAUTENTICARNFSE"
                  value={dadosNfse.SITEAUTENTICARNFSE.valor || ''}
                  onChange={onChangeParametros}
                  autoComplete="off"
                />
              </div>
            </Col>
            <Col md={4}>
              <div className="form-group">
                <label htmlFor="">Dias limite para o envio de RPS</label>
                <Hint
                  classes={`inline clean module-color top-left fa-exclamation-circle mobile xs`}
                >
                  Quantidade de dias
                </Hint>
                <InputInteger
                  name="DIASLIMITEENVIORPS"
                  key="DIASLIMITEENVIORPS"
                  value={dadosNfse.DIASLIMITEENVIORPS.valor || ''}
                  onChange={onChangeParametros}
                  autoComplete="off"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Checkbox
                className="form-group"
                name="HABILITANFSEDATAANTERIOR"
                id="HABILITANFSEDATAANTERIOR"
                checked={dadosNfse.HABILITANFSEDATAANTERIOR.valor === 'S'}
                onChange={onChangeParametros}
                label="Habilitar data anterior NFS-e"
              />
            </Col>
            <Col md={4}>
              <Checkbox
                className="form-group"
                name="OBRIGATORIODADOSDEDUCAO"
                id="OBRIGATORIODADOSDEDUCAO"
                checked={dadosNfse.OBRIGATORIODADOSDEDUCAO.valor === 'S'}
                onChange={onChangeParametros}
                label="Obrigatório informar dados se o item for dedução"
              />
            </Col>
            <Col md={4}>
              <Checkbox
                data-test-id="checkbox-habilitarIrtermediario"
                className="form-group"
                name="HABILITAINTERMEDIARIO"
                id="HABILITAINTERMEDIARIO"
                checked={dadosNfse.HABILITAINTERMEDIARIO.valor === 'S'}
                onChange={onChangeParametros}
                label="Habilitar intermediário"
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Checkbox
                className="form-group"
                name="GERAXMLNFSECONVERTIDA"
                id="GERAXMLNFSECONVERTIDA"
                checked={dadosNfse.GERAXMLNFSECONVERTIDA.valor === 'S'}
                onChange={onChangeParametros}
                label="Gerar XML de NFS-e convertida"
              />
            </Col>
            <Col md={4}>
              <Checkbox
                className="form-group"
                name="CANCELANFSECONVERTIDA"
                id="CANCELANFSECONVERTIDA"
                checked={dadosNfse.CANCELANFSECONVERTIDA.valor === 'S'}
                onChange={onChangeParametros}
                label="Cancelar NFS-e convertida"
              />
            </Col>
            <Col md={4}>
              <Checkbox
                className="form-group"
                name="UTILIZAUNIDADEMEDIDA"
                id="UTILIZAUNIDADEMEDIDA"
                checked={dadosNfse.UTILIZAUNIDADEMEDIDA.valor === 'S'}
                onChange={onChangeParametros}
                label="Utilizar unidade de medida"
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Checkbox
                className="form-group"
                name="NFSE_HAB_INSERCAO_TOMADOR_PJ"
                id="NFSE_HAB_INSERCAO_TOMADOR_PJ"
                checked={dadosNfse.NFSE_HAB_INSERCAO_TOMADOR_PJ.valor === 'S'}
                onChange={onChangeParametros}
                label="Permite inserção de tomador PJ do município"
              />
            </Col>
            <Col md={4}>
              <Checkbox
                className="form-group"
                name="NFSE_HAB_EDICAO_TOMADOR_PJ"
                id="NFSE_HAB_EDICAO_TOMADOR_PJ"
                checked={dadosNfse.NFSE_HAB_EDICAO_TOMADOR_PJ.valor === 'S'}
                onChange={onChangeParametros}
                label="Permite edição de tomador PJ do município"
              />
            </Col>
            <Col md={4}>
              <Checkbox
                className="form-group"
                name="EXIBEEMISSORNOTA"
                id="EXIBEEMISSORNOTA"
                checked={dadosNfse.EXIBEEMISSORNOTA.valor === 'S'}
                onChange={onChangeParametros}
                label="Exibe emissor no relatório de nota fiscal"
              />
            </Col>
            <Col md={4}>
              <Checkbox
                className="form-group"
                name="EXIBECANCELADONOTA"
                id="EXIBECANCELADONOTA"
                checked={dadosNfse.EXIBECANCELADONOTA.valor === 'S'}
                onChange={onChangeParametros}
                label="Exibe solicitador do cancelamento relatório nota fiscal"
              />
            </Col>
            <Col md={4}>
              <Checkbox
                className="form-group"
                name="VALIDA_DADOS_OBRA"
                id="VALIDA_DADOS_OBRA"
                checked={dadosNfse.VALIDA_DADOS_OBRA?.valor === 'S'}
                onChange={onChangeParametros}
                label="Valida os dados da obra"
              />
            </Col>
            <Col md={4}>
              <Checkbox
                className="form-group"
                name="PERMITE_DEDUCAO_NFSE_TRIBUTADA_FORA_MUNICIPIO"
                id="PERMITE_DEDUCAO_NFSE_TRIBUTADA_FORA_MUNICIPIO"
                checked={
                  dadosNfse.PERMITE_DEDUCAO_NFSE_TRIBUTADA_FORA_MUNICIPIO
                    ?.valor === 'S'
                }
                onChange={onChangeParametros}
                label="Permitir percentual tributação fora município"
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Checkbox
                className="form-group"
                name="EXIGE_ANEXO_CANCELAMENTO_NFSE_SERVICO_NAO_PRESTADO"
                id="EXIGE_ANEXO_CANCELAMENTO_NFSE_SERVICO_NAO_PRESTADO"
                checked={
                  dadosNfse.EXIGE_ANEXO_CANCELAMENTO_NFSE_SERVICO_NAO_PRESTADO
                    ?.valor === 'S'
                }
                onChange={onChangeParametros}
                label="Exige anexo(evidência) de serviço não prestado para cancelamento de NFS-e"
              />
            </Col>
            <Col md={4}>
              <Checkbox
                className="form-group"
                name="SIMPLES_NACIONAL_VALOR_SERVICOS_AUTOMATICAMENTE"
                id="SIMPLES_NACIONAL_VALOR_SERVICOS_AUTOMATICAMENTE"
                checked={
                  dadosNfse.SIMPLES_NACIONAL_VALOR_SERVICOS_AUTOMATICAMENTE
                    ?.valor === 'S'
                }
                onChange={onChangeParametros}
                label="Simples Nacional - Buscar valor dos Serviços Automaticamente"
              />
            </Col>
            <Col md={4}>
              <Checkbox
                className="form-group"
                name="ALTERARDATAPRESTACAOSERVICONFSE"
                id="ALTERARDATAPRESTACAOSERVICONFSE"
                checked={
                  dadosNfse.ALTERARDATAPRESTACAOSERVICONFSE?.valor === 'S'
                }
                onChange={onChangeParametros}
                label="Permitir alterar a data da prestação de serviços da nota fiscal"
              />
            </Col>
            <Col md={4}>
              <Checkbox
                className="form-group"
                name="HABILITA_BLOQUEIO_TOMADOR_NAO_IDENTIFICADO"
                id="HABILITA_BLOQUEIO_TOMADOR_NAO_IDENTIFICADO"
                checked={
                  dadosNfse.HABILITA_BLOQUEIO_TOMADOR_NAO_IDENTIFICADO
                    ?.valor === 'S'
                }
                onChange={onChangeParametros}
                label="Habilita a opção tomador não identificado pelo cadastro contribuinte"
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <div className="form-group">
                <label htmlFor="">Recalcular impostos federais</label>
                <select
                  name="RECALCULARIMPOSTOSFEDERAIS"
                  value={dadosNfse.RECALCULARIMPOSTOSFEDERAIS?.valor}
                  onChange={onChangeParametros}
                >
                  <option value="S">Sempre</option>
                  <option value="N">Nunca</option>
                  <option value="I">
                    Somente quando imposto não for alterado por contribuinte
                  </option>
                </select>
              </div>
            </Col>
            <Col md={4}>
              <div className="form-group">
                <label htmlFor="">Fluxo de cancelamento NFS-e</label>
                <select
                  name="FLUXOCANCELAMENTONFSE"
                  value={dadosNfse.FLUXOCANCELAMENTONFSE.valor}
                  onChange={onChangeParametros}
                >
                  {tiposFluxoCancelamentoNfse &&
                    tiposFluxoCancelamentoNfse.map(tipo => (
                      <option key={tipo.tipo} value={tipo.tipo}>
                        {tipo.tipo} - {tipo.descricao}
                      </option>
                    ))}
                </select>
              </div>
            </Col>
            <Col md={2}>
              {dadosNfse.FLUXOCANCELAMENTONFSE.valor === '5' ? (
                <div className="form-group">
                  <label htmlFor="">Autorizar cancelamento até</label>
                  <Hint
                    classes={`inline clean module-color top-left fa-exclamation-circle mobile xs`}
                  >
                    Esse parâmetro, se aplica apenas ao fluxo de cancelamento
                    NFS-e, tipo 5. O qual define a data limite para cancelamento
                    na competência subsequente a emissão da NFS-e.
                  </Hint>
                  <InputInteger
                    name="DATA_LIMITE_CANCELAMENTO_COMPETENCIA"
                    key="DATA_LIMITE_CANCELAMENTO_COMPETENCIA"
                    value={
                      dadosNfse.DATA_LIMITE_CANCELAMENTO_COMPETENCIA.valor || ''
                    }
                    onChange={onChangeParametros}
                    autoComplete="off"
                  />
                </div>
              ) : (
                <div className="form-group">
                  <label htmlFor="">Solicitar aprovação após</label>
                  <Hint
                    classes={`inline clean module-color top-left fa-exclamation-circle mobile xs`}
                  >
                    Esse parâmetro, se aplica apenas ao fluxo de cancelamento
                    NFS-e, tipo 2. O qual define a quantidade de dias que deverá
                    ser aplicado esta regra.
                  </Hint>
                  <InputInteger
                    name="QTDEDIASCANCELAMENTONFSE"
                    key="QTDEDIASCANCELAMENTONFSE"
                    value={dadosNfse.QTDEDIASCANCELAMENTONFSE.valor || ''}
                    onChange={onChangeParametros}
                    disabled={dadosNfse.FLUXOCANCELAMENTONFSE.valor !== '2'}
                    autoComplete="off"
                  />
                </div>
              )}
            </Col>
            <Col md={2}>
              <div className="form-group">
                <label htmlFor="">Remover LOG WS após</label>
                <Hint
                  classes={`inline clean module-color top-left fa-exclamation-circle mobile xs`}
                >
                  Quantidade de dias
                </Hint>
                <InputInteger
                  name="DIAS_PARA_REMOVER_SOLICITACAOWS"
                  key="DIAS_PARA_REMOVER_SOLICITACAOWS"
                  value={dadosNfse.DIAS_PARA_REMOVER_SOLICITACAOWS.valor || ''}
                  onChange={onChangeParametros}
                  autoComplete="off"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <div className="form-group">
                <label htmlFor="">Fluxo de substituição NFS-e</label>
                <select
                  name="FLUXOSUBSTITUICAONFSE"
                  value={dadosNfse.FLUXOSUBSTITUICAONFSE.valor}
                  onChange={onChangeParametros}
                >
                  {tiposFluxoSubstituicaoNfse &&
                    tiposFluxoSubstituicaoNfse.map(tipo => (
                      <option key={tipo.tipo} value={tipo.tipo}>
                        {tipo.tipo} - {tipo.descricao}
                      </option>
                    ))}
                </select>
              </div>
            </Col>
            <Col md={2}>
              {dadosNfse.FLUXOSUBSTITUICAONFSE.valor === '5' ? (
                <div className="form-group">
                  <label htmlFor="">Autorizar substituição até</label>
                  <Hint
                    classes={`inline clean module-color top-left fa-exclamation-circle mobile xs`}
                  >
                    Esse parâmetro, se aplica apenas ao fluxo de substituição
                    NFS-e, tipo 5. O qual define a data limite para substituição
                    na competência subsequente a emissão da NFS-e.
                  </Hint>
                  <InputInteger
                    name="DATA_LIMITE_SUBSTITUICAO_COMPETENCIA"
                    key="DATA_LIMITE_SUBSTITUICAO_COMPETENCIA"
                    value={
                      dadosNfse.DATA_LIMITE_SUBSTITUICAO_COMPETENCIA.valor || ''
                    }
                    onChange={onChangeParametros}
                    autoComplete="off"
                  />
                </div>
              ) : (
                <div className="form-group">
                  <label htmlFor="">Solicitar aprovação após</label>
                  <Hint
                    classes={`inline clean module-color top-left fa-exclamation-circle mobile xs`}
                  >
                    Esse parâmetro, se aplica apenas ao fluxo de substituição
                    NFS-e, tipo 2. O qual define a quantidade de dias que deverá
                    ser aplicado esta regra.
                  </Hint>
                  <InputInteger
                    name="QTDEDIASSUBSTITUICAONFSE"
                    key="QTDEDIASSUBSTITUICAONFSE"
                    value={dadosNfse.QTDEDIASSUBSTITUICAONFSE.valor || ''}
                    onChange={onChangeParametros}
                    disabled={dadosNfse.FLUXOSUBSTITUICAONFSE.valor !== '2'}
                    autoComplete="off"
                  />
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Parametro
              type="textarea"
              name="OBSERVACAO_FIXA_NFSE"
              label='Observação Fixa (anexada ao campo "Observação")'
              size={12}
              value={dadosNfse.OBSERVACAO_FIXA_NFSE.valor}
              onChange={onChangeParametros}
            />
          </Row>
          <Row>
            <Col md={6}>
              <Checkbox
                data-test-id="checkbox-habilitarBloqueioMei"
                className="form-group"
                name="BLOQUEAR_NFS_E_MEI"
                id="BLOQUEAR_NFS_E_MEI"
                checked={dadosNfse.BLOQUEAR_NFS_E_MEI?.valor === 'S'}
                onChange={onChangeParametros}
                label="Habilita bloqueio de emissão de Nfs-e para MEI"
              />
            </Col>
          </Row>
          {dadosNfse.BLOQUEAR_NFS_E_MEI?.valor === 'S' && (
            <Row>
              <Parametro
                type="textarea"
                name="MENSAGEM_BLOQUEAR_NFS_E_MEI"
                label="Mensagem exibida no bloqueio de Nfs-e para MEI"
                size={12}
                value={dadosNfse.MENSAGEM_BLOQUEAR_NFS_E_MEI.valor}
                onChange={onChangeParametros}
              />
            </Row>
          )}
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default NfseForm;
/* <Col md={4}>
    <Checkbox
      className="form-group"
      name="habilitarNfseRetroativa"
      id="habilitarNfseRetroativa"
      checked={dadosNfse.habilitarNfseRetroativa}
      onChange={onChangeParametros}
      label="Habilitar NFS-e retroativa"
    />
  </Col>
  <Col md={4}>
    <Checkbox
      className="form-group"
      name="emitePdfHomologacao"
      id="emitePdfHomologacao"
      checked={dadosNfse.emitePdfHomologacao}
      onChange={onChangeParametros}
      label="Emitir PDF homologação"
    />
  </Col>
  */
