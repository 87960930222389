import { FilterUtils } from '@elotech/components';
import axios, { AxiosPromise } from 'axios';
import { ServiceUtils } from 'iss-common/utils';

import { CONTEXT_PATH } from './contextPath';

const defaultSort = { sort: 'id' };

const search = (params: string) => (params ? `search=${params}` : '');

const filterAutocomplete = (search: any) => {
  if (search) {
    return FilterUtils.buildFilter(['titulo', 'contaContabil'])(search);
  }

  return '';
};

export const loadCosif = (searchParams: any, page: number) =>
  axios.get(`${CONTEXT_PATH}/cosif?${search(searchParams)}`, {
    params: { ...defaultSort, page: page }
  });

export const loadCosifAutoComplete = (searchParams: any, page = 50) =>
  axios.get(
    `${CONTEXT_PATH}/cosif?${ServiceUtils.buildSearchParams(
      filterAutocomplete(searchParams)
    )}`,
    {
      params: { ...defaultSort, page: page }
    }
  );

export const importarArquivoCosif = (files: any): AxiosPromise<any> =>
  axios.post(`${CONTEXT_PATH}/cosif/importar-arquivos`, files);
