import { ActionButton, SectionTitle } from '@elotech/components';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import Credenciamento from '../step4-credenciamento/Credenciamento';
import AcessosList from './AcessosList';
import Arquivo from './Arquivo';
import ArquivosList from './ArquivosList';
import DadosEmpresariais from './DadosEmpresariais';

const DadosGerais = ({
  disabled,
  historicoAgendamento,
  onChangeDadosEmpresariais,
  error,
  errorArquivo,
  tiposImagens,
  visualizarAcessos,
  visualizarArquivos,
  showFormAcessos,
  showFormArquivos,
  excluirArquivo,
  downloadArquivo,
  arquivoEmpresa,
  onUploadFile,
  onChangeTipoImagem,
  showFormOpcoes,
  visualizarOpcoes,
  isCadastroAvulso,
  rejeitarOuHomologarCredenciar,
  onChangeObservacoes,
  onFinish
}) => {
  return (
    <Fragment>
      <SectionTitle>Dados Empresariais</SectionTitle>
      <DadosEmpresariais
        disabled={disabled}
        historicoAgendamento={historicoAgendamento}
        onChangeDadosEmpresariais={onChangeDadosEmpresariais}
        error={error}
        isCadastroAvulso={isCadastroAvulso}
      />

      <SectionTitle hasButton={true}>
        Arquivos
        <ActionButton
          key="view-button"
          icon="eye"
          label="Visualizar Arquivos"
          onClick={visualizarArquivos}
        />
      </SectionTitle>
      {showFormArquivos ? (
        <Arquivo
          disabled={disabled}
          arquivo={arquivoEmpresa}
          tiposImagens={tiposImagens}
          errorArquivo={errorArquivo}
          onUploadFile={onUploadFile}
          onChangeTipoImagem={onChangeTipoImagem}
        />
      ) : (
        ''
      )}
      {showFormArquivos ? (
        <ArquivosList
          disabled={disabled}
          arquivos={historicoAgendamento.arquivos}
          excluirArquivo={excluirArquivo}
          downloadArquivo={downloadArquivo}
        />
      ) : (
        ''
      )}

      <SectionTitle hasButton={true}>
        Acessos
        <ActionButton
          key="view-button"
          icon="eye"
          label="Visualizar acessos"
          onClick={visualizarAcessos}
        />
      </SectionTitle>
      {showFormAcessos ? (
        <AcessosList acessos={historicoAgendamento.acessos} />
      ) : (
        ''
      )}

      {isCadastroAvulso && (
        <SectionTitle hasButton={true}>
          Opções
          <ActionButton
            key="view-button"
            icon="eye"
            label="Visualizar opções"
            onClick={visualizarOpcoes}
          />
        </SectionTitle>
      )}
      {showFormOpcoes && isCadastroAvulso ? (
        <Credenciamento
          disabled={!historicoAgendamento.permiteAprovarOuRejeitar}
          historicoAgendamento={historicoAgendamento}
          rejeitarOuHomologarCredenciar={rejeitarOuHomologarCredenciar}
          onChangeObservacoes={onChangeObservacoes}
          isCadastroAvulso={true}
        />
      ) : (
        ''
      )}

      {isCadastroAvulso && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <button
            data-test-id="btnFinalizarCredenciamento"
            className="btn positive inline mt-xs"
            type="button"
            onClick={onFinish}
          >
            Finalizar
          </button>
        </div>
      )}
    </Fragment>
  );
};

DadosGerais.propTypes = {
  historicoAgendamento: PropTypes.object.isRequired,
  error: PropTypes.object.isRequired,
  errorArquivo: PropTypes.object.isRequired,
  visualizarAcessos: PropTypes.func.isRequired,
  showFormAcessos: PropTypes.bool.isRequired,
  visualizarArquivos: PropTypes.func.isRequired,
  showFormArquivos: PropTypes.bool.isRequired,
  arquivoEmpresa: PropTypes.object.isRequired,
  tiposImagens: PropTypes.array,
  onChangeTipoImagem: PropTypes.func.isRequired,
  showFormOpcoes: PropTypes.bool.isRequired,
  visualizarOpcoes: PropTypes.func.isRequired,
  isCadastroAvulso: PropTypes.bool.isRequired,
  rejeitarOuHomologarCredenciar: PropTypes.func,
  onChangeObservacoes: PropTypes.func,
  onFinish: PropTypes.func
};

export default DadosGerais;
