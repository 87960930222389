import { ControleDmsNaoEntregueDTO } from '../../../types';

export const getFiltrotext = (filtro?: string) => {
  if (!filtro) {
    return '';
  }

  const COM_MOVIMENTO = 'CM';
  const SEM_MOVIMENTO = 'S';

  const filtroDTO: ControleDmsNaoEntregueDTO = JSON.parse(filtro);

  return [
    filtroDTO?.pessoa ? `Pessoa/Empresa: ${filtroDTO.pessoa?.cnpjCpf}` : '',
    filtroDTO?.cadastroGeral ? `Cadastro(s): ${filtroDTO.cadastroGeral}` : '',
    filtroDTO?.somenteAtivos ? 'Somente Ativos' : '',
    filtroDTO?.tipoMovimentoSigla === COM_MOVIMENTO
      ? 'Tipo Movimento: Com Movimento'
      : filtroDTO.tipoMovimentoSigla === SEM_MOVIMENTO
      ? 'Tipo Movimento: Sem Movimento'
      : 'Tipo Movimento: Todos',
    filtroDTO?.competenciaInicial
      ? `Competência Inicial: ${filtroDTO.competenciaInicial}`
      : '',
    filtroDTO?.competenciaFinal
      ? `Competência Final: ${filtroDTO.competenciaFinal}`
      : '',
    filtroDTO?.regimesFiscais
      ? filtroDTO.regimesFiscais
          .map(regime => {
            return `Regime Fiscal: ${regime.id} - ${regime.descricao}`;
          })
          .join(', ')
      : '',
    filtroDTO?.valorDmsInicial
      ? `Valor DMS Inicial: ${filtroDTO.valorDmsInicial.toLocaleString(
          'pt-BR',
          { style: 'currency', currency: 'BRL' }
        )}`
      : '',
    filtroDTO?.valorDmsFinal
      ? `Valor DMS Final: ${filtroDTO.valorDmsFinal.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL'
        })}`
      : '',
    filtroDTO?.somentePrestador ? 'Somente Prestador' : '',
    filtroDTO?.somenteTomador ? 'Somente Tomador' : ''
  ]
    .filter(Boolean)
    .join(', ');
};
