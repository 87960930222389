import { ActionButton, SectionTitle } from '@elotech/components';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import ContatoCredenciamento from './ContatoCredenciamento';
import ObservacoesTextArea from './ObservacoesTextArea';

const Credenciamento = ({
  disabled,
  historicoAgendamento,
  rejeitarOuHomologarCredenciar,
  onChangeObservacoes,
  isCadastroAvulso
}) => {
  const linksSomenteTomador = [
    {
      label: 'Homologar',
      onClick: () => rejeitarOuHomologarCredenciar('somenteTomador', true)
    },
    {
      label: 'Rejeitar',
      onClick: () => rejeitarOuHomologarCredenciar('somenteTomador', false)
    }
  ];

  const linksDmsTomadoPrestado = [
    {
      label: 'Homologar',
      onClick: () => rejeitarOuHomologarCredenciar('dmsTomadoPrestado', true)
    },
    {
      label: 'Rejeitar',
      onClick: () => rejeitarOuHomologarCredenciar('dmsTomadoPrestado', false)
    }
  ];

  const linksEmissaoNfse = [
    {
      label: 'Homologar',
      onClick: () => rejeitarOuHomologarCredenciar('emissaoNfse', true)
    },
    {
      label: 'Rejeitar',
      onClick: () => rejeitarOuHomologarCredenciar('emissaoNfse', false)
    }
  ];

  const linksDmsBanco = [
    {
      label: 'Homologar',
      onClick: () => rejeitarOuHomologarCredenciar('dmsBanco', true)
    },
    {
      label: 'Rejeitar',
      onClick: () => rejeitarOuHomologarCredenciar('dmsBanco', false)
    }
  ];

  const linksLancamentoServicos = [
    {
      label: 'Homologar',
      onClick: () => rejeitarOuHomologarCredenciar('lancamentoServicos', true)
    },
    {
      label: 'Rejeitar',
      onClick: () => rejeitarOuHomologarCredenciar('lancamentoServicos', false)
    }
  ];

  return (
    <Fragment>
      {!isCadastroAvulso && (
        <>
          <SectionTitle>Contato de Credenciamento</SectionTitle>
          <ContatoCredenciamento historicoAgendamento={historicoAgendamento} />
          <SectionTitle>Credenciar</SectionTitle>
        </>
      )}
      <div className="panel table table-responsive">
        <div className="panel-body">
          <div className="panel-table-outer">
            <table className="panel-table striped fancy">
              <thead>
                <tr>
                  <th>Descrição</th>
                  <th>Situação</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr>
                  {!isCadastroAvulso && (
                    <>
                      <td>Emissão NFS-e</td>
                      <td>
                        {historicoAgendamento.emissaoNfse
                          ? 'Homologado'
                          : 'Rejeitado'}
                      </td>
                      <td>
                        <div className="btn-actions">
                          <div className="btn-actions-inner">
                            {disabled ? (
                              ''
                            ) : (
                              <ActionButton
                                data-test-id={`rejeitarOuHomologarEmissaoNfse`}
                                key="rejeitarOuHomologarEmissaoNfse"
                                icon="file-alt"
                                links={linksEmissaoNfse}
                              />
                            )}
                          </div>
                        </div>
                      </td>
                    </>
                  )}
                  {isCadastroAvulso && (
                    <>
                      <td>Lançamento de serviços</td>
                      <td>
                        {historicoAgendamento.lancamentoServicos
                          ? 'Homologado'
                          : 'Rejeitado'}
                      </td>
                      <td>
                        <div className="btn-actions">
                          <div className="btn-actions-inner">
                            {disabled ? (
                              ''
                            ) : (
                              <ActionButton
                                data-test-id={`rejeitarOuHomologarLancamentoServicos`}
                                key="rejeitarOuHomologarLancamentoServicos"
                                icon="file-alt"
                                links={linksLancamentoServicos}
                              />
                            )}
                          </div>
                        </div>
                      </td>
                    </>
                  )}
                </tr>
                <tr>
                  <td>DMS Tomados e Prestados</td>
                  <td>
                    {historicoAgendamento.dmsTomadoPrestado
                      ? 'Homologado'
                      : 'Rejeitado'}
                  </td>
                  <td>
                    <div className="btn-actions">
                      <div className="btn-actions-inner">
                        {disabled ? (
                          ''
                        ) : (
                          <ActionButton
                            data-test-id={`rejeitarOuHomologarDmsTomadoPrestado`}
                            key="rejeitarOuHomologarDmsTomadoPrestado"
                            icon="file-alt"
                            links={linksDmsTomadoPrestado}
                          />
                        )}
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Somente Tomador</td>
                  <td>
                    {historicoAgendamento.somenteTomador
                      ? 'Homologado'
                      : 'Rejeitado'}
                  </td>
                  <td>
                    <div className="btn-actions">
                      <div className="btn-actions-inner">
                        {disabled ? (
                          ''
                        ) : (
                          <ActionButton
                            data-test-id={`rejeitarOuHomologarSomenteTomador`}
                            key="rejeitarOuHomologarSomenteTomador"
                            icon="file-alt"
                            links={linksSomenteTomador}
                          />
                        )}
                      </div>
                    </div>
                  </td>
                </tr>
                {historicoAgendamento.febraban &&
                  historicoAgendamento.febraban.id && (
                    <tr>
                      <td>DMS Instituição Financeira</td>
                      <td data-test-id={`rejeitadoOuHomologadoDmsBanco`}>
                        {historicoAgendamento.dmsBanco
                          ? 'Homologado'
                          : 'Rejeitado'}
                      </td>
                      <td>
                        <div className="btn-actions">
                          <div className="btn-actions-inner">
                            {disabled ? (
                              ''
                            ) : (
                              <ActionButton
                                data-test-id={`rejeitarOuHomologarDmsBanco`}
                                key="rejeitarOuHomologarDmsBanco"
                                icon="file-alt"
                                links={linksDmsBanco}
                              />
                            )}
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <b className="dd-title">Observações</b>
      <ObservacoesTextArea
        historicoAgendamento={historicoAgendamento}
        onChangeObservacoes={onChangeObservacoes}
        disabled={disabled}
      />
    </Fragment>
  );
};

Credenciamento.propType = {
  historicoAgendamento: PropTypes.object.isRequired,
  rejeitarOuHomologarCredenciar: PropTypes.func.isRequired,
  onChangeObservacoes: PropTypes.func.isRequired,
  isCadastroAvulso: PropTypes.bool.isRequired
};

export default Credenciamento;
