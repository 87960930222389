import { Row, SectionTitle } from '@elotech/components';
import React from 'react';

import Parametro from '../component/Parametro';

const TributosForm = ({
  dadosTributos,
  onToggleTributos,
  showFormTributos,
  onChangeParametros
}) => {
  return (
    <>
      <SectionTitle hasButton={true}>
        Servidores Oxy
        <button
          className="dropdown-arrow"
          data-test-id="button"
          onClick={onToggleTributos}
        >
          <i
            className={
              showFormTributos ? 'fa fa-chevron-up' : 'fa fa-chevron-down'
            }
          />
        </button>
      </SectionTitle>
      {showFormTributos ? (
        <>
          <Row>
            <Parametro
              type="text"
              hintText="Atualização Cadastral"
              name="URLOXYTRIBUTOS"
              value={dadosTributos.URLOXYTRIBUTOS.valor || ''}
              onChange={onChangeParametros}
              size={12}
              label="URL do servidor Oxy Tributos"
            />
          </Row>
          <Row>
            <Parametro
              type="text"
              hintText="Credenciamento Automático"
              name="URLOXYISSADMIN"
              value={dadosTributos.URLOXYISSADMIN.valor || ''}
              onChange={onChangeParametros}
              size={12}
              label="URL do servidor Oxy ISS Admin"
            />
          </Row>
        </>
      ) : (
        ''
      )}
    </>
  );
};

export default TributosForm;
