import axios from 'axios';

import { CONTEXT_PATH } from './contextPath';

const defaultSort = { sort: 'id' };

export const BASE_URL = 'cancelamento-nota-fiscal';

const search = params => (params ? `search=${params}` : '');

export const loadCancelamentosNotasFiscais = (searchParams, page) =>
  axios.get(
    `${CONTEXT_PATH}/cancelamento-nota-fiscal?${search(searchParams)}`,
    {
      params: { ...defaultSort, ...page }
    }
  );

export const loadSolicitacaoCancelamentoById = id =>
  axios.get(`${CONTEXT_PATH}/cancelamento-nota-fiscal/${id}`);

export const rejeitarSolicitacaoCancelamento = solicitacao =>
  axios.put(
    `${CONTEXT_PATH}/cancelamento-nota-fiscal/rejeitar/${solicitacao.id}`,
    solicitacao
  );

export const aprovarSolicitacaoCancelamento = solicitacao =>
  axios.put(
    `${CONTEXT_PATH}/cancelamento-nota-fiscal/aprovar/${solicitacao.id}`,
    solicitacao
  );

export const geraUrlAnexo = arquivo =>
  axios.get(
    `${CONTEXT_PATH}/cancelamento-nota-fiscal/download-anexo/${arquivo.idArquivo}`
  );

export const enviarEmail = idCancelamento =>
  axios.post(
    `${CONTEXT_PATH}/cancelamento-nota-fiscal/enviar-email/${idCancelamento}`
  );

export const cancelarNota = solicitacao =>
  axios.post(
    `${CONTEXT_PATH}/cancelamento-nota-fiscal/cancelar-nota-fiscal`,
    solicitacao
  );

export const estornarCancelamentoNota = estornoCancelamentoNotaFiscalDTO =>
  axios.post(
    `${CONTEXT_PATH}/${BASE_URL}/estornar-cancelamento-nota-fiscal`,
    estornoCancelamentoNotaFiscalDTO
  );
