const asRoleType = <
  T extends { [K in keyof T]: { name: K; description: string } }
>(
  t: T
) => t;

export const Roles = asRoleType({
  credenciamento_read: {
    name: 'credenciamento_read',
    description: 'Permite consultar credenciamento'
  },
  usuarios_issqn_read: {
    name: 'usuarios_issqn_read',
    description: 'Permite consultar usuários ISSQN'
  },
  usuarios_issqn_write: {
    name: 'usuarios_issqn_write',
    description: 'Permite cadastrar e alterar usuários ISSQN'
  },
  usuarios_issqn_admin_write: {
    name: 'usuarios_issqn_admin_write',
    description:
      'Permite cadastrar e alterar usuários ISSQN como Administradores'
  },
  cancelamento_nfse_read: {
    name: 'cancelamento_nfse_read',
    description: 'Permite consultar cancelamento de NFSe'
  },
  substituicao_nfse_read: {
    name: 'substituicao_nfse_read',
    description: 'Permite consultar substituição de NFSe'
  },
  cancelamento_dms_read: {
    name: 'cancelamento_dms_read',
    description: 'Permite consultar cancelamento de DMS'
  },
  cancelamento_guia_read: {
    name: 'cancelamento_guia_read',
    description: 'Permite consultar cancelamento de guia'
  },
  incentivos_fiscais_read: {
    name: 'incentivos_fiscais_read',
    description: 'Permite consultar os incentivos fiscais'
  },
  incentivos_fiscais_write: {
    name: 'incentivos_fiscais_write',
    description: 'Permite cadastrar e alterar os incentivos fiscais'
  },
  bloqueio_operacoes_read: {
    name: 'bloqueio_operacoes_read',
    description: 'Permite consultar os bloqueio de operações'
  },
  bloqueio_operacoes_write: {
    name: 'bloqueio_operacoes_write',
    description: 'Permite cadastrar e alterar os bloqueio de operações'
  },
  cadastro_febraban_read: {
    name: 'cadastro_febraban_read',
    description: 'Permite consultar o cadastro FEBRABAN'
  },
  cadastro_febraban_write: {
    name: 'cadastro_febraban_write',
    description: 'Permite cadastrar e alterar o cadastro FEBRABAN'
  },
  solicitacoes_rps_read: {
    name: 'solicitacoes_rps_read',
    description: 'Permite consultar as solicitações de RPS'
  },
  guias_eventuais_read: {
    name: 'guias_eventuais_read',
    description: 'Permite consultar as guias eventuais'
  },
  grupo_anexo_publico_read: {
    name: 'grupo_anexo_publico_read',
    description: 'Permite consultar o grupo anexo público'
  },
  grupo_anexo_publico_write: {
    name: 'grupo_anexo_publico_write',
    description: 'Permite cadastrar e alterar o grupo anexo público'
  },
  anexo_publico_read: {
    name: 'anexo_publico_read',
    description: 'Permite consultar o anexo público'
  },
  anexo_publico_write: {
    name: 'anexo_publico_write',
    description: 'Permite cadastrar e alterar o anexo público'
  },
  parametros_read: {
    name: 'parametros_read',
    description: 'Permite consultar os parâmetros'
  },
  divida_dms_read: {
    name: 'divida_dms_read',
    description: 'Permite consultar a dívida - DMS'
  },
  divida_dms_write: {
    name: 'divida_dms_write',
    description: 'Permite cadastrar e alterar a dívida - DMS'
  },
  certificado_read: {
    name: 'certificado_read',
    description: 'Permite consultar o certificado'
  },
  vencimento_dividas_dms_read: {
    name: 'vencimento_dividas_dms_read',
    description: 'Permite consultar o vencimento das dívidas - DMS'
  },
  modelo_relatorio_read: {
    name: 'modelo_relatorio_read',
    description: 'Permite consultar o modelo de relatório'
  },
  modelo_relatorio_write: {
    name: 'modelo_relatorio_write',
    description: 'Permite cadastrar e alterar o modelo de relatório'
  },
  template_mensagem_email_read: {
    name: 'template_mensagem_email_read',
    description: 'Permite consultar o template de mensagem de email'
  },
  template_mensagem_email_write: {
    name: 'template_mensagem_email_write',
    description: 'Permite cadastrar e alterar o template de mensagem de email'
  },
  concurso_read: {
    name: 'concurso_read',
    description: 'Permite consultar os concursos'
  },
  concurso_write: {
    name: 'concurso_write',
    description: 'Permite cadastrar e alterar os concursos'
  },
  exclusao_participante_read: {
    name: 'exclusao_participante_read',
    description: 'Permite consultar a exclusão de participante'
  },
  exclusao_participante_write: {
    name: 'exclusao_participante_write',
    description: 'Permite cadastrar e alterar a exclusão de participante'
  },
  importacao_candidatos_read: {
    name: 'importacao_candidatos_read',
    description: 'Permite consultar a importação dos candidatos'
  },
  prestacao_contas_read: {
    name: 'prestacao_contas_read',
    description: 'Permite consultar a prestação de contas'
  },
  denuncias_read: {
    name: 'denuncias_read',
    description: 'Permite consultar as denúncias'
  },
  controle_dms_nao_entregues_read: {
    name: 'controle_dms_nao_entregues_read',
    description: 'Permite consultar o controle de DMS não entregues'
  },
  controle_dms_nao_entregues_write: {
    name: 'controle_dms_nao_entregues_write',
    description: 'Permite alterar o controle de DMS não entregues'
  },
  nfse_update: {
    name: 'nfse_update',
    description: 'Permite editar NFSe'
  },
  plano_orientador_read: {
    name: 'plano_orientador_read',
    description: 'Permite consultar o plano de contas orientador'
  },
  virada_exercicio_update: {
    name: 'virada_exercicio_update',
    description: 'Permite processar a virada de exercício'
  },
  importacao_arquivo_dimp_update: {
    name: 'importacao_arquivo_dimp_update',
    description: 'Permite realizar a importação arquivo DIMP'
  },
  importacao_nfse_nacional_read: {
    name: 'importacao_nfse_nacional_read',
    description: 'Permite consultar a importação NFS-e nacional'
  },
  rel_nfse_emitidas_read: {
    name: 'rel_nfse_emitidas_read',
    description: 'Permite consultar o relatório de NFS-e Emitidas'
  },
  rel_dms_pgdas_geral_read: {
    name: 'rel_dms_pgdas_geral_read',
    description:
      'Permite consultar o relatório Simples Nacional - DMS x PGDAS x DIMP (Geral)'
  },
  rel_dms_entregue_read: {
    name: 'rel_dms_entregue_read',
    description: 'Permite consultar o relatório Declaração Mensal Entregue'
  },
  rel_limite_faturamento_mei_read: {
    name: 'rel_limite_faturamento_mei_read',
    description: 'Permite consultar o relatório MEI - Empresas Acima do Limite'
  },
  rel_pgdas_regime_fixo_read: {
    name: 'rel_pgdas_regime_fixo_read',
    description:
      'Permite consultar o relatório Simples Nacional - Declarações de Regime Fixo'
  },
  rel_pgdas_declaracoes_bens_moveis_read: {
    name: 'rel_pgdas_declaracoes_bens_moveis_read',
    description:
      'Permite consultar o relatório Simples Nacional - Declarações de Locação de Bens/Moveis'
  },
  rel_pgdas_servicos_exterior_read: {
    name: 'rel_pgdas_servicos_exterior_read',
    description:
      'Permite consultar o relatório Simples Nacional - Serviços Prestados ao Exterior'
  },
  rel_pgdas_empresas_nao_estabelecidas_read: {
    name: 'rel_pgdas_empresas_nao_estabelecidas_read',
    description:
      'Permite consultar o relatório Simples Nacional - Empresas não estabelecidas no Município'
  },
  gerenciador_tarefas_read: {
    name: 'gerenciador_tarefas_read',
    description: 'Permite consultar o gerenciador de tarefas'
  },
  rel_pgdas_declaracoes_isentas_imunes_red_aliquota_read: {
    name: 'rel_pgdas_declaracoes_isentas_imunes_red_aliquota_read',
    description:
      'Permite consultar o relatório Simples Nacional - Declarações isentas, imunes ou redução de alíquota'
  },
  rel_pgdas_receitas_outros_municipios_read: {
    name: 'rel_pgdas_receitas_outros_municipios_read',
    description:
      'Permite consultar o relatório Simples Nacional - Receitas declaradas para outros Municípios'
  },
  rel_empresas_liberadas_emissao_nfse_read: {
    name: 'rel_empresas_liberadas_emissao_nfse_read',
    description:
      'Permite consultar o relatório Empresas liberadas emissão NFS-e'
  },
  rel_gerencial_iss_read: {
    name: 'rel_gerencial_iss_read',
    description: 'Permite consultar o relatório Gerencial do ISS'
  },
  rel_comparativo_valor_estimado_valor_prestado_read: {
    name: 'rel_comparativo_valor_estimado_valor_prestado_read',
    description:
      'Permite consultar o relatório Comparativo entre valor ISS estimado X valor prestado'
  },
  cosif_read: {
    name: 'cosif_read',
    description: 'Permite consultar a lista de contas Cosif'
  },
  caixa_postal_read: {
    name: 'caixa_postal_read',
    description: 'Permite consultar e responder mensagens'
  },
  duvidas_read: {
    name: 'duvidas_read',
    description: 'Permite consultar e responder as dúvidas'
  },
  rel_variacao_valores_read: {
    name: 'rel_variacao_valores_read',
    description: 'Permite consultar o relatório de variação de valores'
  },
  nfse_exportar: {
    name: 'nfse_exportar',
    description: 'Permite consultar e exportar NFS-e'
  },
  nfse_permite_estornar_cancelamento: {
    name: 'nfse_permite_estornar_cancelamento',
    description: 'Permite Estornar o Cancelamento de Nota Fiscal'
  },
  importacao_arquivo_pgdas_update: {
    name: 'importacao_arquivo_pgdas_update',
    description: 'Permite realizar a importação arquivo PGDAS'
  }
});
