import {
  ActionButton,
  ActionsGroup,
  Alert,
  Container,
  FormattedDate,
  Hint,
  Loading,
  Panel,
  SearchPagination,
  Table,
  formatUtils,
  useAuth,
  useShowNotification
} from '@elotech/components';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { loadCadastroMobiliarios } from '../../../service/CadastroGeralService';
import { estornarCancelamentoNota } from '../../../service/CancelamentoNotaFiscalService';
import {
  excluirXML,
  loadNotaFiscal
} from '../../../service/EditarNotaFiscalService';
import { Roles } from '../../../utils/Roles';
import CancelarNfseFormPage from './CancelarNfseFormPage';
import EditarNfseInputSearch from './EditarNfseInputSearch';

const EditarNfseListPage: React.FC = () => {
  const { hasRole } = useAuth();
  const [loading, setLoading] = useState(false);
  const [notasFiscais, setNotasFiscais] = useState([]);
  const [pagination, setPagination] = useState({});
  const [expandedValueIndex, setExpandedValueIndex] = useState(undefined);
  const history = useHistory();
  const user = useSelector((state: any) => state?.user?.profile);
  const showNotification = useShowNotification();

  const [
    editarNotaFiscalInputSearch,
    setEditarNotaFiscalInputSearch
  ] = useState({
    contribuinte: '',
    tipoServico: 'P',
    homologacao: 'N',
    dataInicial: '',
    dataFinal: '',
    numDocumento: ''
  });

  const [
    errorEditarNotaFiscalInputSearch,
    setErrorEditarNotaFiscalInputSearch
  ] = useState({
    contribuinte: false,
    dataInicial: false,
    dataFinal: false
  });

  const searchWithPage = (page: any) => {
    serviceSearch(page);
  };

  const serviceSearch = (page?: any): void => {
    if (hasErrorsInputSearch()) {
      showNotification({
        level: 'error',
        message: 'Campos obrigatórios.'
      });
    } else {
      searchWithParameters(page);
    }
  };

  const searchWithParameters = (page: any) => {
    const searchParams = montarParametrosFiltroUrl();

    setLoading(true);

    loadNotaFiscal(searchParams, page)
      .then(getLoadNotaFiscalSuccess)
      .catch(getLoadNotaFiscalError);
  };

  const montarParametrosFiltroUrl = () => {
    const {
      contribuinte,
      tipoServico,
      homologacao,
      dataInicial,
      dataFinal,
      numDocumento
    }: any = editarNotaFiscalInputSearch;

    let parametrosPesquisa = `tipoMovimento=='${tipoServico}' and homologacao=='${homologacao}' `;

    if (contribuinte) {
      parametrosPesquisa = parametrosPesquisa.concat(
        `and notaFiscalPrestador.cadastroGeral.id==${contribuinte.id} `
      );
    }

    if (numDocumento) {
      parametrosPesquisa = parametrosPesquisa.concat(`and
      numeroNotaFiscal==${numDocumento} `);
    }

    if (dataInicial) {
      parametrosPesquisa = parametrosPesquisa.concat(`and
      dataEmissao=ge='${dataInicial}' `);
    }

    if (dataFinal) {
      parametrosPesquisa = parametrosPesquisa.concat(`and
      dataEmissao=le='${dataFinal}' `);
    }

    return parametrosPesquisa;
  };

  const getLoadNotaFiscalSuccess = (response: any) => {
    const {
      content,
      number,
      totalPages,
      first,
      last,
      numberOfElements,
      size
    } = response.data;
    setNotasFiscais(content);
    setLoading(false);
    setPagination({
      number,
      totalPages,
      first,
      last,
      numberOfElements,
      size
    });
  };

  const getLoadNotaFiscalError = () => {
    setLoading(false);
    console.log('error 1');
    Alert.error({
      title: 'Ocorreu uma falha ao carregar notas fiscais!'
    });
  };

  const validatorsEditarNotaFiscalInputSearch = {
    contribuinte: (value: any) => value,
    dataInicial: (value: any) => value,
    dataFinal: (value: any) => value
  };

  const onChangeInputSearch = (event: any) => {
    const { name, value } = event.target;

    setEditarNotaFiscalInputSearch(prev => ({
      ...prev,
      [name]: value
    }));

    setErrorEditarNotaFiscalInputSearch(prev => ({
      ...prev,
      [name]:
        validatorsEditarNotaFiscalInputSearch[name] &&
        !validatorsEditarNotaFiscalInputSearch[name](value)
    }));
  };

  const onEstornarNotaCancelada = (idNotaFiscal: number) => {
    Alert.question({
      title: 'Deseja estornar cancelamento da nota fiscal?',
      text: 'Esta ação não poderá ser revertida!'
    }).then((res: any) => {
      if (res.value) {
        estornarCancelamentoNota({
          idNotaFiscal
        })
          .then(res => {
            Alert.info({
              title: `Estorno realizado com sucesso`
            });
            searchWithParameters(1);
          })
          .catch(error => {
            Alert.error(
              { title: 'Ocorreu um erro ao estornar o cancelamento da NFS-e' },
              error
            );
          });
      }
    });
  };

  const onEditTomador = (idNotaFiscal: number) => {
    history.push(`/rotinas/editar-tomador/${idNotaFiscal}`);
  };

  const onExcludeXML = (idNotaFiscal: number) => {
    Alert.question({
      title: 'Deseja excluir XML?',
      input: 'textarea',
      inputPlaceholder: 'Descreva o motivo da exclusão',
      inputValidator: motivoExclusaoXmlValidator,
      text: 'Esta ação não poderá ser revertida!'
    }).then((result: any) => {
      if (result.value) {
        const dadosExclusao = {
          idNotaFiscal: idNotaFiscal,
          motivo: result.value,
          usuario: user
        };
        excluirXML(dadosExclusao)
          .then(() => Alert.success({ title: 'XML excluído com sucesso!' }))
          .catch(error => {
            console.log('error 1');

            Alert.error(
              { title: 'Ocorreu uma falha ao tentar excluir XML!' },
              error
            );
          });
      }
    });
  };

  const motivoExclusaoXmlValidator = (value: any) => {
    return new Promise<any>(resolve => {
      if (value) {
        resolve();
      } else {
        resolve('Escreva o motivo da exclusão!');
      }
    });
  };

  const hasErrorsInputSearch = () => {
    const errorsCamposObrigatorios = Object.keys(
      editarNotaFiscalInputSearch
    ).filter(field => {
      return (
        validatorsEditarNotaFiscalInputSearch[field] &&
        !validatorsEditarNotaFiscalInputSearch[field](
          editarNotaFiscalInputSearch[field]
        )
      );
    });

    const objErro: any = errorsCamposObrigatorios.reduce((total, current) => {
      total[current] = true;
      return total;
    }, {});

    setErrorEditarNotaFiscalInputSearch(objErro);
    return errorsCamposObrigatorios.length > 0;
  };

  const onSelect = (name: any, value: any) => {
    setEditarNotaFiscalInputSearch(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const optionLabel = (option: any) => {
    if (option.pessoa) {
      return (
        formatUtils.formatCpfCnpj(option.pessoa.cnpjCpf) +
        ' - ' +
        option.pessoa.nome
      );
    }
    return '';
  };

  const renderFormCancelarNfse = (nota: any, index: any) => {
    if (expandedValueIndex !== index) {
      return null;
    }

    return (
      <CancelarNfseFormPage
        idNotaFiscal={nota.id}
        onCloseForms={onCloseForms}
        onSearch={serviceSearch}
      />
    );
  };

  const onCancelNfse = (index: any) => {
    setExpandedValueIndex(prev => (prev === index ? undefined : index));
  };

  const onCloseForms = () => {
    setExpandedValueIndex(undefined);
    setNotasFiscais([]);
  };

  const habilitaBotao = (nota: any) => {
    return nota.situacao !== 1;
  };

  const disableButtonEstornoCancel = (nota: any) => {
    return nota.situacao !== 1;
  };

  const permiteEstornoCancel = () => {
    return hasRole(Roles.nfse_permite_estornar_cancelamento.name);
  };

  return (
    <Container
      breadcrumb
      titleRightComponent={
        <a
          href="https://atendimento.elotech.com.br/kb/pt-br/article/null/oxy-issadmin-editar-nota-fiscal"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Hint classes="inline clean module-color center-right fa-question-circle">
            Ajuda?
          </Hint>
        </a>
      }
    >
      <Loading loading={loading} />
      <EditarNfseInputSearch
        onChangeInputSearch={onChangeInputSearch}
        editarNotaFiscalInputSearch={editarNotaFiscalInputSearch}
        errorEditarNotaFiscalInputSearch={errorEditarNotaFiscalInputSearch}
        search={serviceSearch}
        loadCadastrosMobiliarios={loadCadastroMobiliarios}
        onSelect={onSelect}
        optionLabel={optionLabel}
        pagination={pagination}
      />
      <Panel isTable className="mt-xs">
        <Table
          values={notasFiscais || []}
          keyExtractor={(nota: any) => nota.id}
          renderInnerComponent={renderFormCancelarNfse}
        >
          <Table.Column
            header="Nº Documento"
            value={(nota: any) => nota.numeroNotaFiscal}
          />
          <Table.Column
            header="Razão Social"
            value={(nota: any) => nota.razaoSocialNome}
          />
          <Table.Column
            header="Tipo Documento"
            value={(nota: any) => nota.tipoDocumento}
          />
          <Table.Column
            header="Data Emissão"
            value={(nota: any) => (
              <FormattedDate value={nota.dataEmissao || ''} timeZone="UTC" />
            )}
          />
          <Table.Column
            header="Situação"
            value={(nota: any) => nota.descricaoSituacao}
          />

          <Table.Column
            header=""
            value={(nota: any, index) => (
              <ActionsGroup>
                <ActionButton
                  data-test-id={`buttonEdit${nota.id}`}
                  key="edit-button"
                  icon="pencil-alt"
                  label="Editar"
                  onClick={() => onEditTomador(nota.id)}
                />
                {nota.situacao !== 1 && (
                  <ActionButton
                    data-test-id={`buttonCancel${nota.id}`}
                    key="cancel-button"
                    icon="file-excel"
                    label="Cancelar NFSe"
                    disabled={!habilitaBotao(nota)}
                    onClick={() => onCancelNfse(index)}
                  />
                )}
                <ActionButton
                  data-test-id={`buttonDelete${nota.id}`}
                  key="delete-button"
                  icon="trash-alt"
                  label="Excluir XML"
                  onClick={() => onExcludeXML(nota.id)}
                />
                {permiteEstornoCancel() && (
                  <ActionButton
                    data-test-id={`buttonEstornoCancel${nota.id}`}
                    key="estorno-cancel-button"
                    icon="file-signature"
                    label="Estornar cancelamento"
                    disabled={disableButtonEstornoCancel(nota)}
                    onClick={() => onEstornarNotaCancelada(nota.id)}
                  />
                )}
              </ActionsGroup>
            )}
          />
        </Table>

        {notasFiscais && (
          <SearchPagination page={pagination} searchWithPage={searchWithPage} />
        )}
      </Panel>
    </Container>
  );
};

export default EditarNfseListPage;
