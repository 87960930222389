import {
  Col,
  Container,
  DragDropFiles,
  FAB,
  Field,
  Hint,
  Loading,
  Panel,
  Row,
  SearchFilter,
  SearchPagination,
  Table,
  usePagedQuery
} from '@elotech/components';
import { Formik, FormikActions, FormikProps } from 'formik';
import { Alert } from 'iss-common/utils';
import React, { useState } from 'react';

import { CosifService } from '../../../service';
import { ArquivoCosif, Cosif } from '../../../types/Cosif';

const fields: Field[] = [
  {
    label: 'Conta Contábil',
    name: 'contaContabil',
    type: 'STRING'
  },
  {
    label: 'Título',
    name: 'titulo',
    type: 'STRING'
  }
];

const initialValue: ArquivoCosif = { arquivos: [] };

const CosifListPage: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [arquivos, setArquivos] = useState<File[]>([]);

  const {
    loading: loadingPages,
    values,
    pagination,
    doSearch,
    doPagedSearch
  } = usePagedQuery<Cosif>({
    search: (search, pagination) =>
      CosifService.loadCosif(search, pagination?.page ?? 0),
    onError: error => {
      Alert.error(
        {
          title: `Erro ao buscar as Cosifs`
        },
        error
      );
    }
  });

  const onSubmit = (
    form: ArquivoCosif,
    formikActions: FormikActions<ArquivoCosif>
  ) => {
    const formData = new FormData();
    form.arquivos.forEach(arquivo => {
      formData.append('files', arquivo);
    });

    setLoading(true);
    CosifService.importarArquivoCosif(formData)
      .then(() => {
        setArquivos([]);
        formikActions?.setFieldValue('arquivos', []);

        Alert.success({
          title: 'Arquivo(s) importado(s) com sucesso'
        });
      })
      .catch(error => {
        Alert.error({ title: 'Erro ao importar arquivos' }, error);
      })
      .finally(() => setLoading(false));
  };

  const addFile = (formprops: any, newFiles: File[]) => {
    const files: File[] = [...arquivos, ...newFiles];
    setArquivos(files);

    formprops?.setFieldValue('arquivos', files);
  };

  const onRemove = (formprops: any, index: number) => {
    const files: File[] =
      arquivos.length === 1 ? [] : [...arquivos].splice(index, 1);
    setArquivos(files);

    formprops?.setFieldValue('arquivos', files);
  };

  return (
    <Container breadcrumb>
      <Loading loading={loading || loadingPages} />
      <Formik<ArquivoCosif>
        enableReinitialize
        onSubmit={onSubmit}
        initialValues={initialValue}
        render={(formProps: FormikProps<ArquivoCosif>) => (
          <>
            <Panel
              isForm
              title={
                <>
                  Importação do Plano de Contas Cosif
                  <Hint
                    classes={`inline clean module-color center-left fa-question-circle mobile`}
                  >
                    Formato aceito: .zip - os arquivos podem ser encontrados{' '}
                    <a href="https://www.bcb.gov.br/estabilidadefinanceira/cosiftransferenciaarquivos">
                      www.bcb.gov.br/estabilidadefinanceira/cosiftransferenciaarquivos
                    </a>
                  </Hint>
                </>
              }
            >
              <Row>
                <Col sm={12} className="form-group">
                  <DragDropFiles
                    data-testid="[drag-drop-arquivo]"
                    maxSize={1000000000}
                    files={formProps.values.arquivos}
                    onAddFile={files => addFile(formProps, files)}
                    onRemoveFile={index => onRemove(formProps, index)}
                    acceptedFiles={'.zip'}
                    multiple={true}
                  ></DragDropFiles>
                </Col>
              </Row>
            </Panel>
            {formProps.values?.arquivos?.length > 0 && (
              <div className="btn-save">
                <FAB
                  data-test-id="btn-save"
                  icon="check"
                  onClick={formProps.submitForm}
                  title="Importar"
                />
              </div>
            )}
          </>
        )}
      ></Formik>
      <Panel isTable>
        <SearchFilter fields={fields} search={doSearch} />
        <Table values={values} keyExtractor={item => item?.id}>
          <Table.Column<Cosif> header="ID" value={item => item.id} />
          <Table.Column<Cosif>
            header="Conta Contábil"
            value={item => item.contaContabil}
          />
          <Table.Column<Cosif> header="Título" value={item => item.titulo} />
        </Table>
        {pagination && (
          <SearchPagination page={pagination} searchWithPage={doPagedSearch} />
        )}
      </Panel>
    </Container>
  );
};

export default CosifListPage;
