import { EnumValue } from '../types';

export const tipoMovimento: EnumValue[] = [
  { codigo: 'CM', descricao: 'Com Movimento' },
  { codigo: 'S', descricao: 'Sem Movimento' }
];

export const getDescricao = (tipo: string) => {
  const found = tipoMovimento.find(t => t.codigo === tipo);
  return found ? found.descricao : '';
};
