import { Sidebar, useAuth } from '@elotech/components';
import imgModule from '@elotech/components/assets/img/modules/arrecadacao-portalcontribuinte.png';
import imgModuleMain from '@elotech/components/assets/img/modules/arrecadacao-small.png';
import React, { useEffect, useState } from 'react';

import { ConfiguracaoParametrosService } from '../service';
import { Roles } from '../utils/Roles';
import { BadgeValuesConsumer } from './BadgeValuesProvider';

const AppSidebar = ({ showSidebar }) => {
  const EXIBEISSCONCURSO = 'EXIBIRISSCONCURSO';
  const [showConcurso, setShowConcurso] = useState(false);
  const { hasRole } = useAuth();
  useEffect(() => {
    ConfiguracaoParametrosService.findByParamName(EXIBEISSCONCURSO).then(
      result => {
        setShowConcurso(result.data === 'S');
      }
    );
  }, [showConcurso]);
  return (
    <BadgeValuesConsumer>
      {({ _, getBadgeValue }) =>
        showSidebar ? (
          <Sidebar
            title="ISS - Admin"
            imgModuleMain={imgModuleMain}
            imgModule={imgModule}
            showBadge={true}
          >
            <Sidebar.Item title="Início" to="/" icon="fa fa-home" />

            <Sidebar.Group
              title="Acessos e Permissões"
              icon="fa fa-address-card"
            >
              <Sidebar.Item
                title="Credenciamento"
                to="/acessos-e-permissoes/credenciamento"
                icon="fa fa-calendar-check"
                visible={hasRole(Roles.credenciamento_read.name)}
              />
              <Sidebar.Item
                title="Usuários ISSQN"
                to="/acessos-e-permissoes/usuarios-issqn"
                icon="fa fa-desktop"
                visible={hasRole(Roles.usuarios_issqn_read.name)}
              />
              <Sidebar.Item
                title="Cancelamento de NFSe"
                to="/acessos-e-permissoes/cancelamento-nfse"
                icon="fa fa-file-excel"
                visible={hasRole(Roles.cancelamento_nfse_read.name)}
              />
              <Sidebar.Item
                title="Substituição de NFSe"
                to="/acessos-e-permissoes/substituicao-nfse"
                icon="fa fa-exchange-alt"
                visible={hasRole(Roles.substituicao_nfse_read.name)}
              />
              <Sidebar.Item
                title="Cancelamento de DMS"
                to="/acessos-e-permissoes/cancelamento-dms"
                icon="fa fa-ban"
                visible={hasRole(Roles.cancelamento_dms_read.name)}
              />
              <Sidebar.Item
                title="Cancelamento de Guia"
                to="/acessos-e-permissoes/cancelamento-guia"
                icon="fa fa-ban"
                visible={hasRole(Roles.cancelamento_guia_read.name)}
              />
              <Sidebar.Item
                title="Incentivos Fiscais"
                to="/acessos-e-permissoes/incentivos-fiscais"
                icon="fa fa-donate"
                visible={hasRole(Roles.incentivos_fiscais_read.name)}
              />
              <Sidebar.Item
                title="Bloqueio de Operações"
                to="/acessos-e-permissoes/bloqueio-operacoes"
                icon="fa fa-lock"
                visible={hasRole(Roles.bloqueio_operacoes_read.name)}
              />
              <Sidebar.Item
                title="Cadastro FEBRABAN"
                to="/acessos-e-permissoes/cadastro-febraban"
                icon="fa fa-university"
                visible={hasRole(Roles.cadastro_febraban_read.name)}
              />
              <Sidebar.Item
                title="Solicitações de RPS"
                to="/acessos-e-permissoes/solicitacoes-rps?filters==situacao_Igual=PENDENTE"
                icon="fa fa-donate"
                visible={hasRole(Roles.solicitacoes_rps_read.name)}
              />
              <Sidebar.Item
                title="Visualização de Guias Eventuais"
                to="/acessos-e-permissoes/visualizacao-guias-eventuais"
                icon="fa fa-file"
                visible={hasRole(Roles.guias_eventuais_read.name)}
              />
            </Sidebar.Group>

            <Sidebar.Group title="Anexos Públicos" icon="fa fa-folder-open">
              <Sidebar.Item
                title="Grupo Anexo Público"
                to="/anexos-publicos/grupo-anexo-publico"
                icon="fa fa-folder"
                visible={hasRole(Roles.grupo_anexo_publico_read.name)}
              />
              <Sidebar.Item
                title="Anexo Público"
                to="/anexos-publicos/anexo-publico"
                icon="fa fa-file-signature"
                visible={hasRole(Roles.anexo_publico_read.name)}
              />
            </Sidebar.Group>

            <Sidebar.Group title="Configurações" icon="fa fa-cogs">
              <Sidebar.Item
                title="Parâmetros"
                to="/configuracoes/parametros"
                icon="fa fa-wrench"
                visible={hasRole(Roles.parametros_read.name)}
              />

              <Sidebar.Item
                title="Dívida - DMS"
                to="/configuracoes/divida-dms"
                icon="fa fa-hand-holding-usd"
                visible={hasRole(Roles.divida_dms_read.name)}
              />

              <Sidebar.Item
                title="Certificado"
                to="/configuracoes/certificado"
                icon="fa fa-file-alt"
                visible={hasRole(Roles.certificado_read.name)}
              />

              <Sidebar.Item
                title="Vencimento das Dívidas - DMS"
                to="/configuracoes/vencimentos-das-dividas-dms"
                icon="fa fa-calendar-alt"
                visible={hasRole(Roles.vencimento_dividas_dms_read.name)}
              />
              <Sidebar.Item
                title="Modelo de Relatório"
                to="/configuracoes/modelo-de-relatorio"
                icon="fa fa-file-alt"
                visible={hasRole(Roles.modelo_relatorio_read.name)}
              />

              <Sidebar.Item
                title="Template de Mensagem de Email"
                to="/configuracoes/template-email"
                icon="fa fa-file-alt"
                visible={hasRole(Roles.template_mensagem_email_read.name)}
              />
            </Sidebar.Group>
            {showConcurso && (
              <Sidebar.Group title="Concurso" icon="fa fa-trophy">
                <Sidebar.Item
                  title="Cadastrar Concurso"
                  to="/concurso/consulta"
                  icon="fa fa-file-alt"
                  visible={hasRole(Roles.concurso_read.name)}
                />
                <Sidebar.Item
                  title="Exclusão de Participante"
                  to="/concurso/pessoa-exclusao/consulta"
                  icon="fa fa-file-alt"
                  visible={hasRole(Roles.exclusao_participante_read.name)}
                />
              </Sidebar.Group>
            )}

            <Sidebar.Group
              title="Prestação de Contas Eleição"
              icon="fa fa-dollar-sign"
            >
              <Sidebar.Item
                title="Importação dos Candidatos"
                to="/prestacao-de-contas-eleicao/importacao-dos-candidatos"
                icon="fa fa-file-import"
                visible={hasRole(Roles.importacao_candidatos_read.name)}
              />
              <Sidebar.Item
                title="Prestação de Contas"
                to="/prestacao-de-contas-eleicao/prestacao-contas"
                icon="fa fa-file-export"
                visible={hasRole(Roles.prestacao_contas_read.name)}
              />
            </Sidebar.Group>

            <Sidebar.Item
              title="Denúncias"
              icon="fa fa-volume-up"
              to="/denuncias?filters==situacao_Diferente=CONCLUIDA"
              count={getBadgeValue('denunciasNovas')}
              visible={hasRole(Roles.denuncias_read.name)}
            />

            <Sidebar.Item
              title="Dúvidas"
              icon="fa fa-question"
              to="/duvidas?filters==situacao_Diferente=CONCLUIDA"
              count={getBadgeValue('duvidasNovas')}
              visible={hasRole(Roles.duvidas_read.name)}
            />

            <Sidebar.Item
              title="Caixa Postal"
              icon="fa fa-envelope"
              to="/caixa-postal?filters==situacao_Diferente=RESPONDIDA"
              count={getBadgeValue('mensagensNovas')}
              visible={hasRole(Roles.caixa_postal_read.name)}
            />

            <Sidebar.Group title="Rotinas" icon="fa fa-plus">
              <Sidebar.Item
                title="Controle de DMS Não Entregues"
                to="/rotinas/controle-dms-nao-entregues"
                icon="fa fa-edit"
                visible={hasRole(Roles.controle_dms_nao_entregues_read.name)}
              />
              <Sidebar.Item
                title="Editar NFSe"
                to="/rotinas/editar-nfse"
                icon="fa fa-edit"
                visible={hasRole(Roles.nfse_update.name)}
              />
              <Sidebar.Item
                title="Plano de Contas Orientador"
                to="/rotinas/plano-orientador"
                icon="fa fa-edit"
                visible={hasRole(Roles.plano_orientador_read.name)}
              />
              <Sidebar.Item
                title="Cosif"
                to="/rotinas/cosif"
                icon="fa fa-edit"
                visible={hasRole(Roles.cosif_read.name)}
              />
              <Sidebar.Item
                title="Virada de exercício"
                to="/rotinas/virada-exercicio"
                icon="fa fa-edit"
                visible={hasRole(Roles.virada_exercicio_update.name)}
              />
              <Sidebar.Item
                title="Importação Arquivo DIMP"
                to="/rotinas/importacao-arquivo-dimp"
                icon="fa fa-edit"
                visible={hasRole(Roles.importacao_arquivo_dimp_update.name)}
              />
              <Sidebar.Item
                title="Importação NFS-e Nacional"
                to="/rotinas/importacao-nfse-nacional"
                icon="fa fa-file-import"
                visible={hasRole(Roles.importacao_nfse_nacional_read.name)}
              />
              <Sidebar.Item
                title="Exportar NFSe"
                to="/rotinas/exportar-nfse"
                icon="fa fa-file-download"
                visible={hasRole(Roles.nfse_exportar.name)}
              />
              <Sidebar.Item
                title="Importação Arquivo PGDAS"
                to="/rotinas/importacao-arquivo-pgdas"
                icon="fa fa-file-download"
                visible={hasRole(Roles.importacao_arquivo_pgdas_update.name)}
              />
            </Sidebar.Group>

            <Sidebar.Group title="Relatórios" icon="fa fa-chart-line">
              <Sidebar.Item
                title="NFS-e Emitidas"
                to="/relatorios/relatorio-nfse-emitidas"
                icon="fa fa-chart-line"
                visible={hasRole(Roles.rel_nfse_emitidas_read.name)}
              />
              <Sidebar.Item
                title="Simples Nacional - DMS x PGDAS x DIMP"
                to="/relatorios/dms-pgdas-dimp"
                icon="fa fa-chart-line"
                visible={hasRole(Roles.rel_dms_pgdas_geral_read.name)}
              />
              <Sidebar.Item
                title="Declaração Mensal Entregue"
                to="/relatorios/dms-entregue"
                icon="fa fa-chart-line"
                visible={hasRole(Roles.rel_dms_entregue_read.name)}
              />
              <Sidebar.Item
                title="MEI - Empresas Acima do Limite"
                to="/relatorios/limite-faturamento-mei"
                icon="fa fa-chart-line"
                visible={hasRole(Roles.rel_limite_faturamento_mei_read.name)}
              />
              <Sidebar.Item
                title="Simples Nacional - Declarações de Regime Fixo"
                to="/relatorios/pgdas-regime-fixo"
                icon="fa fa-chart-line"
                visible={hasRole(Roles.rel_pgdas_regime_fixo_read.name)}
              />
              <Sidebar.Item
                title="Simples Nacional - Declarações de Locação Bens/Móveis"
                to="/relatorios/pgdas-declaracoes-bens-moveis"
                icon="fa fa-chart-line"
                visible={hasRole(
                  Roles.rel_pgdas_declaracoes_bens_moveis_read.name
                )}
              />
              <Sidebar.Item
                title="Simples Nacional - Serviços Prestados ao Exterior"
                to="/relatorios/pgdas-servicos-ao-exterior"
                icon="fa fa-chart-line"
                visible={hasRole(Roles.rel_pgdas_servicos_exterior_read.name)}
              />
              <Sidebar.Item
                title="Simples Nacional - Empresas não estabelecidas"
                to="/relatorios/pgdas-empresas-nao-estabelecidas"
                icon="fa fa-chart-line"
                visible={hasRole(
                  Roles.rel_pgdas_empresas_nao_estabelecidas_read.name
                )}
              />
              <Sidebar.Item
                title="Simples Nacional - Declarações Isentas, Imunes ou Red."
                to="/relatorios/pgdas-declaracoes-isentas-imunes"
                icon="fa fa-chart-line"
                visible={hasRole(
                  Roles.rel_pgdas_declaracoes_isentas_imunes_red_aliquota_read
                    .name
                )}
              />
              <Sidebar.Item
                title="Simples Nacional - Receitas de outros Municípios"
                to="/relatorios/pgdas-receitas-outros-municipios"
                icon="fa fa-chart-line"
                visible={hasRole(
                  Roles.rel_pgdas_receitas_outros_municipios_read.name
                )}
              />
              <Sidebar.Item
                title="Empresas liberadas emissão NFS-e"
                to="/relatorios/empresas-liberadas-emissao-nfse"
                icon="fa fa-chart-line"
                visible={hasRole(
                  Roles.rel_empresas_liberadas_emissao_nfse_read.name
                )}
              />
              <Sidebar.Item
                title="Gerencial do ISS"
                to="/relatorios/gerencial-iss"
                icon="fa fa-cogs"
                visible={hasRole(Roles.rel_gerencial_iss_read.name)}
              />
              <Sidebar.Item
                title="Valor estimado X Valor prestado"
                to="/relatorios/valor-estimado-valor-prestado"
                icon="fa fa-chart-line"
                visible={hasRole(
                  Roles.rel_comparativo_valor_estimado_valor_prestado_read.name
                )}
              />
              <Sidebar.Item
                title="Variação valores DMS"
                to="/relatorios/variacao-valores"
                icon="fa fa-percent"
                visible={hasRole(Roles.rel_variacao_valores_read.name)}
              />
            </Sidebar.Group>

            <Sidebar.Item
              title="Gerenciador de Tarefas"
              to="/tarefas"
              icon="fa fa-cogs"
              visible={hasRole(Roles.gerenciador_tarefas_read.name)}
            />
          </Sidebar>
        ) : null
      }
    </BadgeValuesConsumer>
  );
};

export default AppSidebar;
