import { Checkbox, Col, Row, SectionTitle } from '@elotech/components';
import React from 'react';

const RotinasForm = ({
  showFormRotinas,
  onToggleRotinas,
  dadosRotinas,
  onChangeParametros
}) => {
  return (
    <>
      <SectionTitle hasButton={true}>
        Rotinas
        <button
          className="dropdown-arrow"
          data-test-id="button"
          onClick={onToggleRotinas}
        >
          <i
            className={
              showFormRotinas ? 'fa fa-chevron-up' : 'fa fa-chevron-down'
            }
          />
        </button>
      </SectionTitle>
      {showFormRotinas ? (
        <>
          <Row>
            <Col md={4}>
              <Checkbox
                className="form-group"
                name="HABILITAATUALIZACAOCADASTRAL"
                id="HABILITAATUALIZACAOCADASTRAL"
                checked={
                  dadosRotinas.HABILITAATUALIZACAOCADASTRAL.valor === 'S'
                }
                onChange={onChangeParametros}
                label="Habilitar atualização cadastral"
              />
            </Col>
            <Col md={4}>
              <Checkbox
                className="form-group"
                name="HABILITAIMPORTACAONOTAFISCAL"
                id="HABILITAIMPORTACAONOTAFISCAL"
                checked={
                  dadosRotinas.HABILITAIMPORTACAONOTAFISCAL.valor === 'S'
                }
                onChange={onChangeParametros}
                label="Habilitar importação NFS-e"
              />
            </Col>
            <Col md={4}>
              <Checkbox
                className="form-group"
                name="HABILITAGUIAAVULSONOTAFISCAL"
                id="HABILITAGUIAAVULSONOTAFISCAL"
                checked={
                  dadosRotinas.HABILITAGUIAAVULSONOTAFISCAL.valor === 'S'
                }
                onChange={onChangeParametros}
                label="Habilitar guia avulso NFS-e Tomada"
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Checkbox
                className="form-group"
                name="HABILITAPCI"
                id="HABILITAPCI"
                checked={dadosRotinas.HABILITAPCI.valor === 'S'}
                onChange={onChangeParametros}
                label="Habilitar PCI"
              />
            </Col>
            <Col md={4}>
              <Checkbox
                className="form-group"
                name="HABILITACONSULTADMSENTREGUE"
                id="HABILITACONSULTADMSENTREGUE"
                checked={dadosRotinas.HABILITACONSULTADMSENTREGUE.valor === 'S'}
                onChange={onChangeParametros}
                label="Habilitar consultar DMS"
              />
            </Col>
            <Col md={4}>
              <Checkbox
                className="form-group"
                name="HABILITAEMISSAONFSE"
                id="HABILITAEMISSAONFSE"
                checked={dadosRotinas.HABILITAEMISSAONFSE.valor === 'S'}
                onChange={onChangeParametros}
                label="Habilitar emissão NFS-e"
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Checkbox
                className="form-group"
                name="HABILITACONSULTANFSE"
                id="HABILITACONSULTANFSE"
                checked={dadosRotinas.HABILITACONSULTANFSE.valor === 'S'}
                onChange={onChangeParametros}
                label="Habilitar consulta NFS-e"
              />
            </Col>

            <Col md={4}>
              <Checkbox
                className="form-group"
                name="HABILITADIGITACAODOCUMENTOS"
                id="HABILITADIGITACAODOCUMENTOS"
                checked={dadosRotinas.HABILITADIGITACAODOCUMENTOS.valor === 'S'}
                onChange={onChangeParametros}
                label="Habilitar digitação documento"
              />
            </Col>
            <Col md={4}>
              <Checkbox
                className="form-group"
                name="HABILITAUSUARIOSECUNDARIO"
                id="HABILITAUSUARIOSECUNDARIO"
                checked={dadosRotinas.HABILITAUSUARIOSECUNDARIO.valor === 'S'}
                onChange={onChangeParametros}
                label="Habilitar usuário secundário"
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Checkbox
                className="form-group"
                name="HABILITAIMPORTACAODESIF"
                id="HABILITAIMPORTACAODESIF"
                checked={dadosRotinas.HABILITAIMPORTACAODESIF.valor === 'S'}
                onChange={onChangeParametros}
                label="Habilitar importação DESIF"
              />
            </Col>
            <Col md={4}>
              <Checkbox
                className="form-group"
                name="HABILITAIMPORTACAORUBRICA"
                id="HABILITAIMPORTACAORUBRICA"
                checked={dadosRotinas.HABILITAIMPORTACAORUBRICA.valor === 'S'}
                onChange={onChangeParametros}
                label="Habilitar importação rúbrica"
              />
            </Col>
            <Col md={4}>
              <Checkbox
                className="form-group"
                name="HABILITACONSULTALANCBANCOS"
                id="HABILITACONSULTALANCBANCOS"
                checked={dadosRotinas.HABILITACONSULTALANCBANCOS.valor === 'S'}
                onChange={onChangeParametros}
                label="Habilitar consulta lançamento do banco"
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Checkbox
                className="form-group"
                name="HABILITAAUTENTICARDOCFISCAL"
                id="HABILITAAUTENTICARDOCFISCAL"
                checked={dadosRotinas.HABILITAAUTENTICARDOCFISCAL.valor === 'S'}
                onChange={onChangeParametros}
                label="Habilitar autenticação documento fiscal"
              />
            </Col>
            <Col md={4}>
              <Checkbox
                className="form-group"
                name="HABILITACONSULTARCNAESERVICO"
                id="HABILITACONSULTARCNAESERVICO"
                checked={
                  dadosRotinas.HABILITACONSULTARCNAESERVICO.valor === 'S'
                }
                onChange={onChangeParametros}
                label="Habilitar consulta CNAE serviço"
              />
            </Col>
            <Col md={4}>
              <Checkbox
                className="form-group"
                name="HABILITACONSULTINCENTIVOFISCAL"
                id="HABILITACONSULTINCENTIVOFISCAL"
                checked={
                  dadosRotinas.HABILITACONSULTINCENTIVOFISCAL.valor === 'S'
                }
                onChange={onChangeParametros}
                label="Habilitar consulta incentivo fiscal"
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Checkbox
                className="form-group"
                name="HABILITAAGENDARSOLICITACAO"
                id="HABILITAAGENDARSOLICITACAO"
                checked={dadosRotinas.HABILITAAGENDARSOLICITACAO.valor === 'S'}
                onChange={onChangeParametros}
                label="Habilitar agendamento de solicitação"
              />
            </Col>
            <Col md={4}>
              <Checkbox
                className="form-group"
                name="HABILITAINFORMACOESUSUARIO"
                id="HABILITAINFORMACOESUSUARIO"
                checked={dadosRotinas.HABILITAINFORMACOESUSUARIO.valor === 'S'}
                onChange={onChangeParametros}
                label="Habilitar informações de usuário"
              />
            </Col>
            <Col md={4}>
              <Checkbox
                className="form-group"
                name="HABILITAINFORMACOESEMPRESA"
                id="HABILITAINFORMACOESEMPRESA"
                checked={dadosRotinas.HABILITAINFORMACOESEMPRESA.valor === 'S'}
                onChange={onChangeParametros}
                label="Habilitar informações da empresa"
              />
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <Checkbox
                className="form-group"
                name="HABILITASIMPLESMUNICIPAL"
                id="HABILITASIMPLESMUNICIPAL"
                checked={dadosRotinas.HABILITASIMPLESMUNICIPAL.valor === 'S'}
                onChange={onChangeParametros}
                label="Habilitar Simples Municipal"
              />
            </Col>
            <Col md={4}>
              <Checkbox
                className="form-group"
                name="BLOQUEIA_INSERCAO_CNAE_EM_CREDENCIAMENTO"
                id="BLOQUEIA_INSERCAO_CNAE_EM_CREDENCIAMENTO"
                checked={
                  dadosRotinas.BLOQUEIA_INSERCAO_CNAE_EM_CREDENCIAMENTO
                    .valor === 'S'
                }
                onChange={onChangeParametros}
                label="Habilitar bloqueio de CNAE's no credenciamento de empresas"
              />
            </Col>
            <Col md={4}>
              <Checkbox
                className="form-group"
                name="HABILITACARTACORRECAO"
                id="HABILITACARTACORRECAO"
                checked={dadosRotinas.HABILITACARTACORRECAO.valor === 'S'}
                onChange={onChangeParametros}
                label="Habilitar carta de correção"
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Checkbox
                className="form-group"
                name="HABILITAEMISSAONFSEVENTUAL"
                id="HABILITAEMISSAONFSEVENTUAL"
                checked={dadosRotinas.HABILITAEMISSAONFSEVENTUAL.valor === 'S'}
                onChange={onChangeParametros}
                label="Habilitar guia avulso NFS-e Prestada"
              />
            </Col>
            <Col md={4}>
              <Checkbox
                className="form-group"
                name="HABILITASOLCANCELAMENTONFSE"
                id="HABILITASOLCANCELAMENTONFSE"
                checked={dadosRotinas.HABILITASOLCANCELAMENTONFSE.valor === 'S'}
                onChange={onChangeParametros}
                label="Habilitar solicitação de cancelamento NFS-e"
              />
            </Col>
            <Col md={4}>
              <Checkbox
                className="form-group"
                name="HABILITASOLICITACAORPS"
                id="HABILITASOLICITACAORPS"
                checked={dadosRotinas.HABILITASOLICITACAORPS.valor === 'S'}
                onChange={onChangeParametros}
                label="Habilitar solicitação de RPS"
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Checkbox
                className="form-group"
                name="HABILITALANCAMENTORUBRICA"
                id="HABILITALANCAMENTORUBRICA"
                checked={dadosRotinas.HABILITALANCAMENTORUBRICA.valor === 'S'}
                onChange={onChangeParametros}
                label="Habilitar lançamento de Rubrica"
              />
            </Col>
            <Col md={4}>
              <Checkbox
                className="form-group"
                name="HABILITAENTREGADMS"
                id="HABILITAENTREGADMS"
                checked={dadosRotinas.HABILITAENTREGADMS.valor === 'S'}
                onChange={onChangeParametros}
                label="Habilitar entrega de DMS"
              />
            </Col>
            <Col md={4}>
              <Checkbox
                className="form-group"
                name="HABILITASOLCANCELAMENTODMS"
                id="HABILITASOLCANCELAMENTODMS"
                checked={dadosRotinas.HABILITASOLCANCELAMENTODMS.valor === 'S'}
                onChange={onChangeParametros}
                label="Habilitar cancelamento de DMS"
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Checkbox
                className="form-group"
                name="HABILITACONSULTADESIF"
                id="HABILITACONSULTADESIF"
                checked={dadosRotinas.HABILITACONSULTADESIF.valor === 'S'}
                onChange={onChangeParametros}
                label="Habilitar consulta DES-IF"
              />
            </Col>
            <Col md={4}>
              <Checkbox
                className="form-group"
                name="HABILITACAIXAPOSTAL"
                id="HABILITACAIXAPOSTAL"
                checked={dadosRotinas.HABILITACAIXAPOSTAL.valor === 'S'}
                onChange={onChangeParametros}
                label="Habilitar caixa postal"
              />
            </Col>
            <Col md={4}>
              <Checkbox
                className="form-group"
                name="HABILITASOLSUBSTITUICAONFSE"
                id="HABILITASOLSUBSTITUICAONFSE"
                checked={dadosRotinas.HABILITASOLSUBSTITUICAONFSE.valor === 'S'}
                onChange={onChangeParametros}
                label="Habilitar solicitação de substitução NFS-e"
              />
            </Col>
            <Col md={4}>
              <Checkbox
                className="form-group"
                name="HABILITAR_BLOQUEIO_REGIME_FISCAL"
                id="HABILITAR_BLOQUEIO_REGIME_FISCAL"
                checked={
                  dadosRotinas.HABILITAR_BLOQUEIO_REGIME_FISCAL.valor === 'S'
                }
                onChange={onChangeParametros}
                label="Habilitar bloqueio de regime fiscal"
              />
            </Col>
          </Row>
        </>
      ) : (
        ''
      )}
    </>
  );
};
export default RotinasForm;
