import { Alert, Container, NotificationActions } from '@elotech/components';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { ConfigVencimentoDMSService, withService } from '../../../service';
import VencimentoDividaMensalForm from './VencimentoDividaMensalForm';

export class VencimentoDividaMensalFormPage extends Component {
  state = {
    divida: {
      id: '',
      anoCompetencia: '',
      mesCompetencia: '',
      guiaRecolhimento: '',
      dataVencimento: '',
      declaracaoMensalTipoEmpresaVencimento: ''
    },
    error: {
      id: false,
      anoCompetencia: false,
      mesCompetencia: false,
      guiaRecolhimento: false,
      dataVencimento: false,
      declaracaoMensalTipoEmpresaVencimento: false
    },
    loading: false
  };

  loadConfigVencimentoDMSSuccess = response => {
    this.setState({
      divida: response.data,
      loading: false
    });
  };

  loadConfigVencimentoDMSError = error => {
    this.setState({ loading: false });

    Alert.error(
      {
        title: 'Não foi possível carregar a Configuração de Vencimento da DMS.'
      },
      error
    );
  };

  salvarConfigVencimentoDMSSuccess = () => {
    this.setState({ loading: false });

    this.props.showNotification({
      level: 'success',
      message: 'Configuração de Vencimento de DMS salva com sucesso.'
    });

    this.props.history.replace('/configuracoes/vencimentos-das-dividas-dms');
  };

  salvarConfigVencimentoDMSError = error => {
    this.setState({ loading: false });
    let info = '';
    if (error.response) {
      info = error.response.data.message;
    }
    if (info.includes('existe')) {
      Alert.info({
        title: info
      });
    } else {
      Alert.error(
        {
          title: 'Erro ao salvar a Configuração de Vencimento de DMS.'
        },
        error
      );
    }
  };

  onSelect = (name, value) => {
    this.setState(state => {
      return {
        divida: {
          ...state.divida,
          [name]: value
        },
        errors: {
          ...state.errors,
          [name]: false
        }
      };
    });
  };

  componentDidMount() {
    const idConfiguracaoVencimentoDMS = this.props.match.params['id'];

    if (idConfiguracaoVencimentoDMS) {
      this.setState({ loading: true });

      this.props.configVencimentoDMSService
        .carregarConfigVencimentoPorID(idConfiguracaoVencimentoDMS)
        .then(this.loadConfigVencimentoDMSSuccess)
        .catch(this.loadConfigVencimentoDMSError);
    }
  }

  validators = {
    anoCompetencia: ano => ano > 1900,
    dataVencimento: value => value,
    guiaRecolhimento: value => value,
    mesCompetencia: mes => mes && mes >= 1 && mes <= 12,
    declaracaoMensalTipoEmpresaVencimento: value => value,
    id: () => true
  };

  onSave = () => {
    const { divida } = this.state;
    if (this.hasErrors()) {
      this.props.showNotification({
        level: 'error',
        message: 'Campos obrigatórios não preenchidos ou inválidos.'
      });
    } else {
      this.setState({ loading: true });
      this.props.configVencimentoDMSService
        .save(divida)
        .then(this.salvarConfigVencimentoDMSSuccess)
        .catch(this.salvarConfigVencimentoDMSError);
    }
  };

  hasErrors = () => {
    const { divida } = this.state;

    const errors = Object.keys(divida).filter(field => {
      return this.validators[field] && !this.validators[field](divida[field]);
    });

    const objErro = errors.reduce((total, current) => {
      total[current] = true;
      return total;
    }, {});

    this.setState({ error: objErro });
    return errors.length > 0;
  };

  onChangeInputValue = event => {
    const { name, value } = event.target;

    this.setState(state => {
      const { divida, error } = state;
      return {
        divida: { ...divida, [name]: value },
        error: { ...error, [name]: !this.validators[name](value) }
      };
    });
  };

  onChangeAnoCompetencia = event => {
    this.onChangeInputValue(event);
    this.onSelect('guiaRecolhimento', '');
  };

  render() {
    const { error, divida } = this.state;

    return (
      <Container breadcrumb>
        <VencimentoDividaMensalForm
          save={this.onSave}
          cancel={this.onCancel}
          divida={divida}
          onChangeInputValue={this.onChangeInputValue}
          error={error}
          onSelect={this.onSelect}
          onChangeAnoCompetencia={this.onChangeAnoCompetencia}
          loadGuiasRecolhimento={
            this.props.configVencimentoDMSService.loadGuiasRecolhimento
          }
        />
      </Container>
    );
  }
}

const mapDispatchToProps = {
  showNotification: NotificationActions.showNotification
};

const ComponentWithService = withService({
  configVencimentoDMSService: ConfigVencimentoDMSService
})(VencimentoDividaMensalFormPage);

export default connect(null, mapDispatchToProps)(ComponentWithService);
