import {
  Alert,
  Chip,
  Panel,
  SearchPagination,
  Table,
  TableChild,
  usePagedQuery
} from '@elotech/components';
import React, { useCallback, useEffect } from 'react';

import { ContribuinteDeclaracaoMensalNaoEntregueService } from '../../../service';
import {
  DeclaracaoMensalNaoEntregueCadastro,
  DeclaracaoMensalNaoEntregueContribuinte
} from '../../../types';

type Props = {
  idControle: number;
  item: DeclaracaoMensalNaoEntregueCadastro;
  lastSearch?: string;
  filtro: {
    somentePrestador: boolean;
    somenteTomador: boolean;
  };
};

let somentePrestador: boolean = false;
let somenteTomador: boolean = false;

const ContribuinteDeclaracaoMensalNaoEntregueCompetencia: React.FC<Props> = ({
  idControle,
  item,
  lastSearch,
  filtro
}) => {
  const searchCompetencias = useCallback(
    (search, page) =>
      ContribuinteDeclaracaoMensalNaoEntregueService.searchCompetenciaByCadastro(
        idControle,
        item.cadastroGeral!,
        search,
        page
      ),
    [idControle, item.cadastroGeral]
  );

  somentePrestador = !filtro.somenteTomador;
  somenteTomador = !filtro.somentePrestador;

  const {
    doSearch,
    doPagedSearch,
    loading,
    pagination,
    values
  } = usePagedQuery<DeclaracaoMensalNaoEntregueContribuinte>({
    search: searchCompetencias,
    onError: useCallback(error => {
      Alert.error({ title: `Erro ao buscar as competências` }, error);
    }, [])
  });

  useEffect(() => {
    doSearch(lastSearch);
  }, [doSearch, lastSearch]);

  const filteredValues = values.filter(
    item => !(item.prestador === false && item.tomador === false)
  );

  return (
    <TableChild>
      <Panel isTable>
        <Table
          loading={loading}
          values={filteredValues}
          keyExtractor={(item: DeclaracaoMensalNaoEntregueContribuinte) =>
            `${item.id}`
          }
        >
          <Table.Column<DeclaracaoMensalNaoEntregueContribuinte>
            header="Ano"
            value={item => item.anoCompetencia}
          />
          <Table.Column<DeclaracaoMensalNaoEntregueContribuinte>
            header="Mês"
            value={item => item.mesCompetencia}
          />
          {somentePrestador ? (
            <Table.Column<DeclaracaoMensalNaoEntregueContribuinte>
              header="Prestador"
              value={contribuinte =>
                contribuinte.prestador ? (
                  <Chip color="negative" value={'NÃO ENTREGUE'} />
                ) : (
                  ''
                )
              }
            />
          ) : (
            undefined
          )}
          {somenteTomador ? (
            <Table.Column<DeclaracaoMensalNaoEntregueContribuinte>
              header="Tomador"
              value={contribuinte =>
                contribuinte.tomador ? (
                  <Chip color="negative" value={'NÃO ENTREGUE'} />
                ) : (
                  ''
                )
              }
            />
          ) : (
            undefined
          )}
        </Table>
        {pagination && (
          <SearchPagination page={pagination} searchWithPage={doPagedSearch} />
        )}
      </Panel>
    </TableChild>
  );
};

export default ContribuinteDeclaracaoMensalNaoEntregueCompetencia;
