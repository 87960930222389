import {
  AutoComplete,
  Button,
  Col,
  ErrorText,
  InputDate,
  InputInteger,
  Row
} from '@elotech/components';
import React from 'react';

const errorMessages = {
  contribuinte: 'Campo obrigatório.',
  dataInicial: 'Campo obrigatório.',
  dataFinal: 'Campo obrigatório.'
};
const EditarNotaFiscalInputSearch = ({
  onChangeInputSearch,
  editarNotaFiscalInputSearch,
  errorEditarNotaFiscalInputSearch,
  search,
  loadCadastrosMobiliarios,
  onSelect,
  optionLabel,
  pagination
}) => {
  return (
    <>
      <form className="form">
        <Row>
          <Col md={6}>
            <div className="form-group">
              <label htmlFor="contribuinte">Contribuinte</label>
              <AutoComplete
                data-test-id="autocomplete-contribuinte"
                name="contribuinte"
                className={
                  errorEditarNotaFiscalInputSearch.contribuinte ? 'error' : ''
                }
                placeholder="Pesquisar por cnpj ou nome da empresa"
                value={editarNotaFiscalInputSearch.contribuinte}
                onSearch={loadCadastrosMobiliarios}
                onSelect={onSelect}
                getOptionLabel={optionLabel}
                loadOnClick={false}
              />
            </div>
            {errorEditarNotaFiscalInputSearch.contribuinte && (
              <ErrorText>{errorMessages.contribuinte}</ErrorText>
            )}
          </Col>

          <Col md={2}>
            <div className="form-group">
              <label htmlFor="numDocumento">Nº Documento</label>
              <InputInteger
                name="numDocumento"
                value={editarNotaFiscalInputSearch.numDocumento}
                onChange={onChangeInputSearch}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <div className="form-group">
              <label htmlFor="tipoServico">Tipo Serviço</label>
              <select
                name="tipoServico"
                value={editarNotaFiscalInputSearch.tipoServico}
                onChange={onChangeInputSearch}
              >
                <option value="T">Tomados</option>
                <option value="P">Prestados</option>
              </select>
            </div>
          </Col>
          <Col md={3}>
            <div className="form-group">
              <label htmlFor="homologacao">Homologação</label>
              <select
                name="homologacao"
                value={editarNotaFiscalInputSearch.homologacao}
                onChange={onChangeInputSearch}
              >
                <option value="N">Não</option>
                <option value="S">Sim</option>
              </select>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <div className="form-group">
              <label htmlFor="dataInicial">Data Inicial</label>
              <InputDate
                className={
                  errorEditarNotaFiscalInputSearch.dataInicial ? 'error' : ''
                }
                name="dataInicial"
                value={editarNotaFiscalInputSearch.dataInicial || ''}
                onChange={onChangeInputSearch}
              />
              {errorEditarNotaFiscalInputSearch.dataInicial && (
                <ErrorText>{errorMessages.dataInicial}</ErrorText>
              )}
            </div>
          </Col>
          <Col md={3}>
            <div className="form-group">
              <label htmlFor="dataFinal">Data Final</label>
              <InputDate
                className={
                  errorEditarNotaFiscalInputSearch.dataFinal ? 'error' : ''
                }
                name="dataFinal"
                value={editarNotaFiscalInputSearch.dataFinal || ''}
                onChange={onChangeInputSearch}
              />
              {errorEditarNotaFiscalInputSearch.dataFinal && (
                <ErrorText>{errorMessages.dataFinal}</ErrorText>
              )}
            </div>
          </Col>
        </Row>
        <div className="form-group">
          <Button
            data-testid="button-search"
            className="inline"
            iconPosition="left"
            onClick={() => search(pagination)}
          >
            <i className="fa fa-search" />
            BUSCAR
          </Button>
        </div>
      </form>
    </>
  );
};

export default EditarNotaFiscalInputSearch;
